import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'
import { RootState } from '../store'
import { URL } from '../../constants/url'

interface Notification {
  id: number
  type: string
  data: NotificationData
  read: boolean
  created_at: string
  updated_at: string
}

interface NotificationData {
  description: string
  next_service_mileage: number
  related_id: number
  body: string
}

export interface NotificationsState {
  currentNotification: Notification | null
  notifications: Array<Notification>
  isVisibleNotifications: boolean
  isVisibleDeleteModalNotification: boolean
  isPendingGetNotifications: boolean
}

const initialState: NotificationsState = {
  currentNotification: null,
  isVisibleNotifications: false,
  isVisibleDeleteModalNotification: false,
  notifications: [],
  isPendingGetNotifications: true,
}

export const fetchNotifications = createAsyncThunk(
  'notifications/all',
  async (_, thunkAPI) => {
    const params = new URLSearchParams()

    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.notifications}`,
        {
          params,
        }
      )

      console.log(`fetch notifications response = ${JSON.stringify(response)}`)

      if (response.statusText === 'OK') {
        return response.data
      }

      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const setReadNotification = createAsyncThunk(
  'notification/update',
  async (id: number, thunkAPI) => {
    // const formData = new FormData()
    // formData.set('_method', 'put')

    try {
      const response = await axiosInstance.patch(
        `/${URL.notifications}/${id}/read`,
        // formData,
        {
          headers: {
            'Content-Type': 'form-data',
          },
        }
      )

      if (response.statusText === 'OK') {
        const dispatch = thunkAPI.dispatch
        dispatch(fetchNotifications())
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async (_, thunkAPI) => {
    const {
      notifications: { currentNotification },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.notifications}/${currentNotification?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        const dispatch = thunkAPI.dispatch
        dispatch(fetchNotifications())
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setCurrentNotification: (state, action) => {
      return { ...state, currentNotification: action.payload }
    },
    setVisibleNotifications: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleNotifications: action.payload,
        currentNotification: action.payload ? state.currentNotification : null,
      }
    },
    setVisibleDeleteModalNotification: (
      state,
      action: { payload: boolean }
    ) => ({
      ...state,
      isVisibleDeleteModalNotification: action.payload,
    }),
  },
  extraReducers: (builder) => {
    //FOR OWNERS
    builder.addCase(fetchNotifications.pending, (state) => {
      return { ...state, isPendingGetNotifications: true }
    })
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      return {
        ...state,
        notifications: action.payload,
        isPendingGetNotifications: false,
      }
    })
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetNotifications: false }
    })

    builder.addCase(deleteNotification.pending, (state) => {
      return state
    })
    builder.addCase(deleteNotification.fulfilled, (state, action) => {
      toast.success('Успешно удалено!')

      return {
        ...state,
        isVisibleDeleteModalNotification: false,
        currentNotification: null,
        notifications: state.notifications.filter(
          (notification) => notification.id !== state?.currentNotification?.id
        ),
      }
    })
    builder.addCase(deleteNotification.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
  },
})

export const {
  setCurrentNotification,
  setVisibleNotifications,
  setVisibleDeleteModalNotification,
} = notificationsSlice.actions

export default notificationsSlice.reducer
