import classNames from 'classnames'
import styles from './AddCarModal.module.css'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchEditCar,
  setCurrentCar,
  setCurrentTab,
  setIsVisibleModalCar,
} from '../../../redux/reducers/cars'
import { carsSelector } from '../../../redux/selectors/cars-selector'

//@ts-ignore
import ModalImage from 'react-modal-image'
import 'react-photo-view/dist/react-photo-view.css'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import {
  Service,
  fetchAddService,
  fetchServices,
  setCurrentService,
  setIsVisibleDeleteService,
  setIsVisibleModalService,
} from '../../../redux/reducers/services'
import { serviceSelector } from '../../../redux/selectors/service-selector'
import { formatDate } from '../../../utils/date'
import {
  Note,
  fetchAddNote,
  fetchNotes,
  setCurrentNote,
  setIsVisibleDeleteNote,
} from '../../../redux/reducers/notes'
import { notesSelector } from '../../../redux/selectors/notes-selector'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { toast } from 'react-toastify'
import { IMAGE_TYPE } from '../../../constants/file-types'
import { onlyNumberRegularCheck } from '../../../utils/onlyNumberRegularCheck'

type FileArray = File[]

interface ServiceItemProps {
  item: Service
}

const ServiceItem = ({ item }: ServiceItemProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])
  const [coast, setCoast] = useState('')
  const [currency, setCurrency] = useState(item.currency || '');

  const dispatch = useDispatch<AppDispatch>()

  const [date, setDate] = useState('')
  const [mileage, setMileage] = useState<number | string>('')
  const [description, setDescription] = useState('')
  const [nextServiceMileage, setNextServiceMileage] = useState<number | string>(
    ''
  )
  const [photos, setPhotos] = useState<ServiceItemProps['item']['images']>([])
  const [isWasChanged, setIsWasChanged] = useState<number>()

  const textareaRef = useRef(null)

  const handleEditService = (item: Service) => {
    //@ts-ignore
    dispatch(
      fetchAddService({
        date,
        mileage,
        description,
        //@ts-ignore
        nextServiceMileage,
        id: item.id,
        photos: selectedPhotoForSend,
        amount: coast,
        currency,
      })
    ).then((res) => {
      //@ts-ignore
      if (!res.error) {
        // setDescription('')
        setSelectedPhoto([])
        setSelectedPhotoForSend([])
      }
    })
  }

  useEffect(() => {
    console.log(item)
    if (item) {
      setDate(item?.date)
      setMileage(item?.mileage)
      setDescription(item?.description)
      setNextServiceMileage(item?.next_service_mileage)
      setPhotos(item?.images)
      //@ts-ignore
      setCoast(item?.amount)
      setCurrency(item?.currency)
    }
  }, [item])

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [description])

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    setIsWasChanged(id)
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }

      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWasChanged(item.id)
    setCurrency(event.target.value);
    };

  const handleDeleteService = (id: number) => {
    console.log(id)
    dispatch(setCurrentService(id))
    dispatch(setIsVisibleDeleteService(true))
  }

  return (
    <>
      <div
        className={classNames(
          'row',
          styles.small2,
          styles.borderRadius5,
          styles.rowNote,
          styles.bgGray,
          styles.bxShadow
        )}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div
            className={classNames(
              'row',
              'mb-1',
              'align-items-center',
              styles.small3
            )}
          >
            <div className="col-3 col-sm-2 col-md-2 col-lg-2 pr-1">
              <b>{formatDate(date)}</b>
            </div>
            <div className="col-5 col-sm-8 col-md-8 col-lg-8 pl-1">
              <a className={styles.noteName}>{item.user.name}</a>
            </div>
            <div
              className="col-4 col-sm-2 col-md-2 col-lg-2 pl-0  align-content-center"
              style={{ textAlign: 'right' }}
            >
              {!!description.trim() && isWasChanged && (
                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnIcon,
                    styles.btnBlueOutline
                  )}
                  onClick={() => handleEditService(item)}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.iconVerified2,
                      styles.iconBlue
                    )}
                  />{' '}
                </div>
              )}

              <div
                onClick={() => handleDeleteService(item.id)}
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnIcon,
                  styles.btnOutline,
                  styles.deleteButton
                )}
              >
                <i
                  className={classNames(
                    styles.icon,
                    styles.icon15,
                    styles.iconDelete
                  )}
                ></i>
              </div>
            </div>
          </div>

          <textarea
            className={styles.noteText}
            ref={textareaRef}
            value={description}
            onChange={(e) => {
              setIsWasChanged(item.id)
              setDescription(e.target.value)
            }}
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-5 col-sm-3 col-md-3 mt-2">
              <div className="d-inline-block">
                <small className={classNames('d-block', styles.small2)}>
                  Пробег:
                </small>
                <input
                  type="text"
                  // className={classNames(styles.rangeValue, styles.textarea)}
                  className="d-inline-block"
                  value={mileage}
                  style={{ width: '75px' }}
                  onChange={(e) => {
                    if (onlyNumberRegularCheck(e)) {
                      setIsWasChanged(item.id)
                      setMileage(e.target.value)
                    }
                  }}
                />
                <span>{" "}км</span>
              </div>
            </div>
            <div className="col-5 col-sm-3 col-md-3 mt-2">
              <div className="d-inline-block">
                <small className={classNames('d-block', styles.small2)}>
                  След.&nbsp;сервис:
                </small>
                <input
                  type="text"
                  // className={classNames(styles.rangeValue, styles.textarea)}
                  className="d-inline-block"
                  value={nextServiceMileage}
                  style={{ width: '75px' }}
                  onChange={(e) => {
                    if (onlyNumberRegularCheck(e)) {
                      setIsWasChanged(item.id)
                      setNextServiceMileage(e.target.value)
                    }
                  }}
                />
                 <span>{" "}км</span>
              </div>
            </div>
            <div className="col-5 col-sm-3 col-md-3 mt-2">
              <small className={classNames('d-block', styles.small2)}>
                Стоимость:
              </small>
              <input
                type="text"
                placeholder="Сумма"
                value={coast}
                onChange={(e) => {
                  if (onlyNumberRegularCheck(e)) {
                    setIsWasChanged(item.id)
                    setCoast(e.target.value)
                  }
                }}
              />
              <div
                className={classNames(
                  styles.radioTabs,
                  styles.small3,
                  'd-flex flex-wrap justify-content-start'
                )}
                style={{ textAlign: 'left' }}
              >
        <div className="mt-1 mb-1">
<input
name={`currency-${item.id}`}
id={`${item.id}BYN`}
type="radio"
value="BYN"
checked={currency === 'BYN'}
onChange={handleCurrencyChange}
/>
<label className={styles.labelCurrency} htmlFor={`${item.id}BYN`}>
BYN
</label>
</div>
<div className="mt-1 mb-1">
<input
name={`currency-${item.id}`}
id={`${item.id}EUR`}
type="radio"
value="EUR"
checked={currency === 'EUR'}
onChange={handleCurrencyChange}
/>
<label className={styles.labelCurrency} htmlFor={`${item.id}EUR`}>
EUR
</label>
</div>
<div className="mt-1 mb-1">
<input
name={`currency-${item.id}`}
id={`${item.id}RUB`}
type="radio"
value="RUB"
checked={currency === 'RUB'}
onChange={handleCurrencyChange}
/>
<label className={styles.labelCurrency} htmlFor={`${item.id}RUB`}>
RUB
</label>
</div>
              </div>
            </div>
            <div className="col-5 col-sm-3 col-md-3 mt-2">
              <small className={classNames('d-block', styles.small2)}>
                Фото:
              </small>

              <PhotoProvider className={styles.photoProvider}>
                {photos?.map((photo, index) => {
                  return (
                    //@ts-ignore
                    <PhotoView src={photo?.image?.lg}>
                      {index === 0 ? (
                        <a
                          data-lightbox="note_01"
                          className={classNames(
                            styles.btn,
                            styles.btnSmall2,
                            styles.btnBlueOutline,
                            styles.picBtn,
                            'mr-1'
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.icon15,
                              styles.iconPicture
                            )}
                          />
                          {photos.length}
                        </a>
                      ) : (
                        <img style={{ display: 'none' }} />
                      )}
                    </PhotoView>
                  )
                })}
              </PhotoProvider>

              <div className="d-inline-block pt-1 pb-1">
                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnBlueOutline,
                    styles.loadGallery
                  )}
                >
                  <b>+ {!!photos.length ? '' : 'Добавить'}</b>
                  <input
                    multiple={true}
                    onChange={(e) => handlePhotoChange(e, item.id)}
                    type="file"
                    name="report_gallery"
                    id="report_gallery"
                    accept={IMAGE_TYPE}
                  />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!selectedPhoto.length && (
          <div
            className={classNames('col-12 mt-3 mb-3', styles.photoContainer)}
          >
            <PhotoProvider className={styles.photoProvider}>
              {selectedPhoto?.map((photo) => (
                <PhotoView src={photo}>
                  <div className={styles.photoView}>
                    <img src={photo} alt="" />
                    <span
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeletePhoto(photo)
                      }}
                      className={styles.del}
                    >
                      ×
                    </span>
                  </div>
                </PhotoView>
              ))}
            </PhotoProvider>
          </div>
        )}
      </div>
    </>
  )
}

const Services = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { services } = useSelector(serviceSelector)

  return (
    <div className={styles.tabContent} data-content="tab_car_service">
      <div
        className={classNames(
          styles.btn,
          styles.btnSmall2,
          styles.btnBlueOutline
        )}
        onClick={() => dispatch(setIsVisibleModalService(true))}
      >
        + Добавить запись
      </div>
      <div>
        {services?.map((service: Service) => <ServiceItem item={service} />)}
      </div>

      <div className="row mt-3">
        <div
          className="col-12 col-sm-6 mt-1 mb-1"
          style={{ textAlign: 'left' }}
        ></div>
        <div
          className="col-12 col-sm-6 mt-1 mb-1"
          style={{ textAlign: 'right' }}
        >
          <div
            onClick={() => dispatch(setIsVisibleModalCar(false))}
            className={classNames(
              'd-block',
              'd-sm-inline-block',
              styles.btn,
              styles.btnSmall,
              styles.btnBlueOutline
            )}
          >
            Отмена
          </div>
        </div>
      </div>
    </div>
  )
}

interface NoteItemProps {
  item: Note
  setVisibleAddNote: any
}

const NotesItem = ({ item, setVisibleAddNote }: NoteItemProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])
  const { currentCar } = useSelector(carsSelector)

  // date: string
  // description: string
  // completed: boolean
  // images: string[]

  const [date, setDate] = useState('')
  const [completed, setCompleted] = useState<boolean>(false)
  const [description, setDescription] = useState('')
  const [photos, setPhotos] = useState<ServiceItemProps['item']['images']>([])
  const [name, setName] = useState('')
  const [isWasChanged, setIsWasChanged] = useState<number>()

  const textareaRef = useRef(null)
  useEffect(() => {
    if (item) {
      setDate(item?.date)
      setCompleted(item?.completed)
      setDescription(item?.description)
      setPhotos(item?.images)
      setName(item.user.name)
    }
  }, [item])

  const handleEditNote = (id: number) => {
    dispatch(
      fetchAddNote({
        id,
        description,
        completed,
        date,
        photos: selectedPhotoForSend,
      })
    ).then((res) => {
      //@ts-ignore
      if (!res.error) {
        // setDescription('')
        setVisibleAddNote(false)
        setSelectedPhoto([])
        if (currentCar) dispatch(fetchNotes(currentCar.id))
      }
    })
  }

  const onChangeCompleted = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompleted(e.target.checked)

    dispatch(
      fetchAddNote({
        id: item.id,
        description,
        completed: e.target.checked,
        date,
        // photos,
      })
    ).then((res) => {
      //@ts-ignore
      if (!res.error) {
        // setDescription('')
        setVisibleAddNote(false)
        setSelectedPhoto([])
        if (currentCar) dispatch(fetchNotes(currentCar.id))
      }
    })
  }
  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [description])

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    setIsWasChanged(id)
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }

      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  const handleDeleteNotes = (id: number) => {
    dispatch(setCurrentNote(id))
    dispatch(setIsVisibleDeleteNote(true))
  }

  return (
    <div
      className={classNames(
        'row',
        styles.small2,
        styles.borderRadius5,
        styles.rowNote,
        styles.bgGray,
        styles.bxShadow,
        completed ? styles.opacity04 : ''
      )}
    >
      <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-1 pr-1 align-content-center">
        <input
          id={String(item?.id)}
          checked={completed}
          type="checkbox"
          onChange={(e) => {
            onChangeCompleted(e)
          }}
          name={String(item?.id)}
          className={styles.input}
        />
        <label
          className={classNames('mr-2', styles.small3)}
          htmlFor={String(item?.id)}
        >
          <b>{formatDate(date)}</b>
        </label>
        <a className={classNames('mt-1', 'd-inline-block', styles.small3)}>
          {name}
        </a>
      </div>
      <div
        className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1 pl-0  align-content-center"
        style={{ textAlign: 'right' }}
      >
        {!!description.trim() && isWasChanged === item.id && (
          <div
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnIcon,
              styles.btnBlueOutline
            )}
            onClick={() => handleEditNote(item.id)}
          >
            <i
              className={classNames(
                styles.icon,
                styles.iconVerified2,
                styles.iconBlue
              )}
            />{' '}
          </div>
        )}

        <div
          onClick={() => handleDeleteNotes(item.id)}
          className={classNames(
            styles.btn,
            styles.btnSmall2,
            styles.btnIcon,
            styles.btnOutline,
            styles.deleteButton
          )}
        >
          <i
            className={classNames(
              styles.icon,
              styles.icon15,
              styles.iconDelete
            )}
          ></i>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12">
        <textarea
          className={styles.noteText}
          ref={textareaRef}
          value={description}
          onChange={(e) => {
            setIsWasChanged(item.id)
            setDescription(e.target.value)
          }}
          disabled={completed}
        />
      </div>
      {!!selectedPhoto.length && (
        <div className={classNames('col-12 mt-3 mb-3', styles.photoContainer)}>
          <PhotoProvider className={styles.photoProvider}>
            {selectedPhoto?.map((photo) => (
              <PhotoView src={photo}>
                <div className={styles.photoView}>
                  <img src={photo} alt="" />
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeletePhoto(photo)
                    }}
                    className={styles.del}
                  >
                    ×
                  </span>
                </div>
              </PhotoView>
            ))}
          </PhotoProvider>
        </div>
      )}
      <div className="col-12 mt-1" style={{ textAlign: 'right' }}>
        <div
          className={classNames(
            styles.btn,
            styles.btnSmall2,
            styles.btnBlueOutline,
            styles.loadGallery,
            styles.btnLoadWithoutBorder1
          )}
        >
          <div className="d-inline-block pt-1 pb-1">
            <b>+ Фото</b>
            <input
              multiple={true}
              disabled={completed}
              onChange={(e) => {
                handlePhotoChange(e, item.id)
              }}
              type="file"
              name="report_gallery"
              id="report_gallery"
              accept={IMAGE_TYPE}
            />{' '}
          </div>
        </div>
        <PhotoProvider className={styles.photoProvider}>
          {photos?.map((photo, index) => (
            //@ts-ignore
            <PhotoView src={photo.image.lg}>
              {index === 0 ? (
                <a
                  data-lightbox="note_01"
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnBlueOutline,
                    styles.iconBtnImg
                  )}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.icon15,
                      styles.iconPicture
                    )}
                  />
                  {photos.length}
                </a>
              ) : (
                <img style={{ display: 'none' }} />
              )}
            </PhotoView>
          ))}
        </PhotoProvider>
      </div>
    </div>
  )
}

const Notes = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { notes } = useSelector(notesSelector)

  const [visibleAddNote, setVisibleAddNote] = useState(false)
  const textareaRef = useRef(null)
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])

  const [description, setDescription] = useState('')

  const { isPendingAddNote } = useSelector(notesSelector)

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }

      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [description])
  const { currentCar } = useSelector(carsSelector)

  const handleAddNote = async () => {
    dispatch(
      fetchAddNote({
        description,
        photos: selectedPhotoForSend,
      })
    ).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setDescription('')
        setVisibleAddNote(false)
        setSelectedPhoto([])
      }
    })
  }

  return (
    <div className={styles.tabContent} data-content="tab_car_notes">
      <div
        className={classNames(
          styles.btn,
          styles.btnSmall2,
          styles.btnBlueOutline
        )}
        onClick={() => setVisibleAddNote(!visibleAddNote)}
      >
        + Добавить запись
      </div>

      {visibleAddNote && (
        <div
          className={classNames(
            'pl-2 pr-2 pt-2 pb-2',
            styles.small2,
            styles.borderRadius5,
            styles.rowNote,
            styles.bgGray,
            styles.bxShadow
          )}
          // style={{ display: 'none' }}
        >
          <div className={`${styles.small} mb-1`}>Заметка *</div>
          <textarea
            className={`${styles.noteText} mb-3`}
            ref={textareaRef}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div style={{ textAlign: 'left' }}>
            <div
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.loadGallery
              )}
            >
              <i
                className={classNames(
                  styles.icon,
                  styles.icon15,
                  styles.iconPicture
                )}
              ></i>
              + Фото
              <input
                multiple={true}
                onChange={handlePhotoChange}
                type="file"
                name="report_gallery"
                id="report_gallery"
                accept={IMAGE_TYPE}
              />{' '}
            </div>
          </div>

          <div
            className={classNames('col-12 mt-3 mb-3', styles.photoContainer)}
          >
            <PhotoProvider className={styles.photoProvider}>
              {selectedPhoto?.map((photo) => (
                <PhotoView src={photo}>
                  <div className={styles.photoView}>
                    <img src={photo} alt="" />
                    <span
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeletePhoto(photo)
                      }}
                      className={styles.del}
                    >
                      ×
                    </span>
                  </div>
                </PhotoView>
              ))}
            </PhotoProvider>
          </div>

          <button
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnBlue,
              'mr-2'
            )}
            onClick={handleAddNote}
            disabled={isPendingAddNote || !description}
          >
            <i className={classNames(styles.icon, styles.iconCheckWhite)} />{' '}
            Добавить
          </button>
          <button
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnBlueOutline
            )}
            onClick={() => {
              setDescription('')
              setSelectedPhoto([])
              setVisibleAddNote(false)
            }}
            disabled={isPendingAddNote}
          >
            Отмена
          </button>
        </div>
      )}
      <div>
        {notes?.map((item: Note) => (
          <NotesItem
            key={item.id}
            item={item}
            setVisibleAddNote={setVisibleAddNote}
          />
        ))}
      </div>

      <div className="row mt-3">
        <div
          className="col-12 col-sm-6 mt-1 mb-1"
          style={{ textAlign: 'left' }}
        ></div>
        <div
          className="col-12 col-sm-6 mt-1 mb-1"
          style={{ textAlign: 'right' }}
        >
          <div
            onClick={() => dispatch(setIsVisibleModalCar(false))}
            className={classNames(
              'd-block',
              'd-sm-inline-block',
              styles.btn,
              styles.btnSmall,
              styles.btnBlueOutline
            )}
          >
            Отмена
          </div>
        </div>
      </div>
    </div>
  )
}

const MainInfo = ({
  carName,
  setCarName,
  vin,
  setVin,
  number,
  setNumber,
  volume,
  setVolume,
  weight,
  setWeight,
  insuranceEndRb,
  setInsuranceEndRb,
  insuranceEndRf,
  setInsuranceEndRf,
  stateInspectionEnd,
  setStateInspectionEnd,
  isActive,
  setIsActive,
  photo,
  handleSaveCar,
  handleImageChange,
  errors,
  setErrors,
}: any) => {
  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value)
      //@ts-ignore
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: !!e.target.value ? false : true,
      }))
    }

  return (
    <>
      <div className="row mb-3">
        <div className="order-2 col-12 order-sm-1 col-sm-6 mb-3">
          <div className={styles.loadPhoto}>
            <div
              className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
            >
              <ModalImage
                className={`${styles.carImgBg} mb-1`}
                small={photo || 'img/cars/no-car-photo.svg'}
                alt="User Photo"
              />
              <div style={{ textAlign: 'left' }} className={styles.small}>
                <i
                  className={classNames(
                    'mr-1',
                    'd-sm-inline-block',
                    styles.icon,
                    styles.iconDownload,
                    styles.iconUpload,
                    styles.icon16
                  )}
                />{' '}
                Загрузить фото
              </div>
              <input
                type="file"
                accept={IMAGE_TYPE}
                name="photo"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
              />
            </div>
          </div>
        </div>
        <div className="order-1 col-12 order-sm-2 col-sm-6 mb-3">
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Название *
            </small>
            <input
              value={carName}
              onChange={handleInputChange(setCarName, 'carName')}
              required
              type="text"
              placeholder="Название"
              className={errors.carName ? styles.isError : ''}
            />
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              VIN *
            </small>
            <input
              value={vin}
              onChange={handleInputChange(setVin, 'vin')}
              required
              type="text"
              placeholder="VIN номер"
              className={errors.vin ? styles.isError : ''}
            />
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Номер *
            </small>
            <input
              value={number}
              onChange={handleInputChange(setNumber, 'number')}
              required
              type="text"
              placeholder="Гос. номер"
              className={errors.number ? styles.isError : ''}
            />
          </div>

          <div className={styles.small} style={{ textAlign: 'right' }}>
            <input
              id="car_enable"
              checked={isActive}
              type="checkbox"
              onChange={(e) => {
                setIsActive(e.target.checked)
              }}
              name="car_enable"
              className={styles.input}
            />
            <label htmlFor="car_enable">Действует</label>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <div className="mb-2">
            <b>Страховки и техосмотр</b>
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Окончание страховки РБ
            </small>
            <div className={styles.calendar} id="calendar_insurance_date_by">
              <input
                // min={insuranceEndRb || undefined}
                className={classNames(styles.calendarInput, styles.date)}
                type="date"
                value={insuranceEndRb}
                onChange={(e) => setInsuranceEndRb(e.target.value)}
                pattern="\d{4}-\d{2}-\d{2}"
              />
            </div>
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Окончание страховки РФ
            </small>
            <div className={styles.calendar} id="calendar_insurance_date_ru">
              <input
                // min={insuranceEndRf || undefined}
                className={classNames(styles.calendarInput, styles.date)}
                type="date"
                value={insuranceEndRf}
                onChange={(e) => setInsuranceEndRf(e.target.value)}
                pattern="\d{4}-\d{2}-\d{2}"
              />
            </div>
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Окончание гостехосмотра
            </small>
            <div className={styles.calendar} id="calendar_techcheck_date">
              <input
                // min={insuranceEndRf || undefined}
                className={classNames(styles.calendarInput, styles.date)}
                type="date"
                value={stateInspectionEnd}
                onChange={(e) => setStateInspectionEnd(e.target.value)}
                pattern="\d{4}-\d{2}-\d{2}"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <div className="mb-2">
            <b>Вместимость *</b>
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Допустимый объём, м<sup>3</sup> *
            </small>
            <input
              className={classNames(
                'mb-3',
                errors.volume ? styles.isError : ''
              )}
              value={volume}
              onChange={(e) => {
                if (onlyNumberRegularCheck(e)) {
                  setVolume(e.target.value)
                  //@ts-ignore
                  setErrors((prevErrors) => ({ ...prevErrors, volume: false }))
                }
              }}
              required
              type="text"
              placeholder="Допустимый объём"
            />
          </div>
          <div className="mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Допустимая масса, кг *
            </small>
            <input
              value={weight}
              onChange={(e) => {
                if (onlyNumberRegularCheck(e)) {
                  setWeight(e.target.value)
                  //@ts-ignore
                  setErrors((prevErrors) => ({ ...prevErrors, weight: false }))
                }
              }}
              required
              type="text"
              placeholder="Допустимая масса"
              className={errors.weight ? styles.isError : ''}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function AddCarModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentCar, currentTab } = useSelector(carsSelector)

  const [errors, setErrors] = useState({
    carName: false,
    vin: false,
    number: false,
    volume: false,
    weight: false,
  })

  const checkValidation = () => {
    const newErrors = {
      carName: !carName,
      vin: !vin,
      number: !number,
      volume: !volume,
      weight: !weight,
    }

    setErrors(newErrors)
  }

  useClearBodyNoScrollClass()
  // const [currentTab, setCurrentTab] = useState(1)

  const handleSetCurrentTab = (tabNumber: number) => {
    dispatch(setCurrentTab(tabNumber))
  }

  // ADD NEW CAR
  const [carName, setCarName] = useState('')
  const [vin, setVin] = useState('')
  const [number, setNumber] = useState('')

  const [volume, setVolume] = useState<number | string>('')
  const [weight, setWeight] = useState<number | string>('')
  const [insuranceEndRb, setInsuranceEndRb] = useState('')
  const [insuranceEndRf, setInsuranceEndRf] = useState('')
  const [stateInspectionEnd, setStateInspectionEnd] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [photo, setPhoto] = useState<null | string>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setSelectedImage(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setPhoto(imageUrl)
    }
  }

  const handleSaveCar = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const commonParams = {
      carName,
      vin,
      number,
      volume,
      weight,
      insuranceEndRb,
      insuranceEndRf,
      stateInspectionEnd,
      isActive,
      selectedImage,
      handleImageChange,
    }
    const newErrors = {
      carName: !carName,
      vin: !vin,
      number: !number,
      volume: !volume,
      weight: !weight,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      if (currentCar) {
        dispatch(fetchEditCar({ ...commonParams, id: currentCar.id }))
      } else {
        dispatch(fetchEditCar(commonParams))
      }
    }
  }

  const mainProps = {
    carName,
    setCarName,
    vin,
    setVin,
    number,
    setNumber,
    volume,
    setVolume,
    weight,
    setWeight,
    insuranceEndRb,
    setInsuranceEndRb,
    insuranceEndRf,
    setInsuranceEndRf,
    stateInspectionEnd,
    setStateInspectionEnd,
    isActive,
    setIsActive,
    selectedImage,
    setSelectedImage,
    photo,
    setPhoto,
    handleSaveCar,
    handleImageChange,
    errors,
    setErrors,
  }

  useEffect(() => {
    if (currentCar) {
      const {
        name,
        active,
        vin,
        number,
        cargo_volume,
        cargo_weight,
        insurance_end_rb,
        insurance_end_rf,
        state_inspection_end,
        image,
      } = currentCar
      setCarName(name)
      setVin(vin)
      setNumber(number)
      setVolume(cargo_volume)
      setWeight(cargo_weight)
      setInsuranceEndRb(insurance_end_rb)
      setInsuranceEndRf(insurance_end_rf)
      setStateInspectionEnd(state_inspection_end)
      setIsActive(active)
      setPhoto(image?.md)
    }
  }, [])

  useEffect(() => {
    if (currentCar) {
      dispatch(fetchServices(currentCar?.id))
      dispatch(fetchNotes(currentCar?.id))
    }
  }, [currentCar, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setCurrentCar(null))
      dispatch(setCurrentService(null))
      dispatch(setCurrentNote([]))
    }
  }, [])

  const renderComponent = () => {
    switch (currentTab) {
      case 1:
        return <MainInfo {...mainProps} />

      case 2:
        return <Services />

      case 3:
        return <Notes />

      default:
        return <MainInfo />
    }
  }

  useClearBodyNoScrollClass()

  return (
    <div>
      <div style={{ display: 'block' }} className={styles.win}>
        <div className={styles.winContainer}>
          <div className={styles.winContent}>
            <div
              onClick={() => dispatch(setIsVisibleModalCar(false))}
              className={styles.close}
            >
              ×
            </div>
            <div className={styles.winContentFulltext}>
              <h3
                style={{ textAlign: 'center' }}
                className={classNames(`mb-5 ${styles.h3}}`)}
              >
                {currentCar ? carName : 'Добавить транспорт'}
              </h3>
              {!!currentCar && (
                <div
                  className={classNames(
                    'row align-items-end',
                    styles.radioTabsHead
                  )}
                >
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 1 ? styles.menuButtonActive : '',
                      'col-4 col-sm-4 col-md-4 mt-2'
                    )}
                    onClick={() => handleSetCurrentTab(1)}
                  >
                    Основное
                  </div>
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 2 ? styles.menuButtonActive : '',
                      'col-4 col-sm-4 col-md-4 mt-2'
                    )}
                    onClick={() => handleSetCurrentTab(2)}
                  >
                    Сервис
                  </div>
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 3 ? styles.menuButtonActive : '',
                      'col-4 col-sm-4 col-md-4 mt-2'
                    )}
                    onClick={() => handleSetCurrentTab(3)}
                  >
                    Заметки
                  </div>
                </div>
              )}
              <form onSubmit={handleSaveCar}>
                <div className="tabs-content">{renderComponent()}</div>
                {currentTab === 1 && (
                  <div className="row mt-3">
                    <div
                      className="col-12 col-sm-6 mt-1 mb-1"
                      style={{ textAlign: 'left' }}
                    >
                      <button
                        onClick={checkValidation}
                        className={classNames(
                          'd-block',
                          'd-sm-inline-block',
                          styles.btn
                        )}
                        // disabled={isDisabledButton}
                      >
                        <i
                          className={`${styles.icon} ${styles.iconCheckWhite}`}
                        />{' '}
                        Сохранить
                      </button>
                    </div>
                    <div
                      className="col-12 col-sm-6 mt-1 mb-1"
                      style={{ textAlign: 'right' }}
                    >
                      <div
                        onClick={() => dispatch(setIsVisibleModalCar(false))}
                        className={classNames(
                          'd-block',
                          'd-sm-inline-block',
                          styles.btn,
                          styles.btnSmall,
                          styles.btnBlueOutline
                        )}
                      >
                        Отмена
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCarModal
