import { useEffect, useState } from 'react'
import HeaderMain from '../../components/header-main/HeaderMain'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import {
  fetchUser,
  fetchUsers,
  fetchUsersForAdmin,
  setCurrentUser,
  setIsVisibleDeleteModalUser,
  setIsVisibleModalUser,
} from '../../redux/reducers/users'
import { usersSelector } from '../../redux/selectors/users-selector'

import styles from './Employees.module.css'
import classNames from 'classnames'
import { User } from '../../redux/reducers/reports'
import { getRole } from '../../utils/getRole'
import { companySelector } from '../../redux/selectors/company-selector'
import Spinner from '../../components/spinner/Spinner'
import EditButton from '../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../components/buttons/actions-button/DeleteButton'

const { isOwner, isAdmin } = getRole()
const TITLE_NAMES = ['ID', 'Сотрудник', 'E-mail', 'Статус', 'Доступ']

const roles = {
  driver: 'Водитель',
  assistant: 'Помощник',
  admin: 'Админ',
  owner: 'Владелец',
}

function Employee() {
  const dispatch = useDispatch<AppDispatch>()
  const { users, isPendingGetUser } = useSelector(usersSelector)
  const { currentCompany } = useSelector(companySelector)
  const [sortField, setSortField] = useState<number | string | null>(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortNewUsers, setSortNewUsers] = useState<any>([])

  const handleEditUser = async (item: User) => {
    await dispatch(fetchUser(item.id))
    dispatch(setIsVisibleModalUser(true))
  }

  const handleDeleteUser = (item: User) => {
    dispatch(setCurrentUser(item))
    dispatch(setIsVisibleDeleteModalUser(true))
  }

  useEffect(() => {
    dispatch(fetchUsers())

    // if (isAdmin && currentCompany) {
    //   dispatch(fetchUsersForAdmin(currentCompany.id))
    // }
  }, [dispatch, currentCompany, users.length])

  const isVisibleAddButton = isAdmin
    ? currentCompany?.id
      ? true
      : false
    : true

  const sortReports = (field: any) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)

    const sortedUsers = [...users].sort((a, b) => {
      let valueA, valueB

      switch (field) {
        case 'ID':
          valueA = new Date(a.id)
          valueB = new Date(b.id)
          break
        case 'Сотрудник':
          valueA = a.name
          valueB = b.name
          break
        case 'E-mail':
          valueA = a.login || ''
          valueB = b.login || ''
          break
        case 'Статус':
          valueA = a.role
          valueB = b.role
          break
        case 'Доступ':
          valueA = a.active
          valueB = b.active
          break
        default:
          return 0
      }

      if (valueA < valueB) {
        return order === 'asc' ? -1 : 1
      }
      if (valueA > valueB) {
        return order === 'asc' ? 1 : -1
      }
      return 0
    })

    setSortNewUsers(sortedUsers)
  }

  useEffect(() => {
    setSortNewUsers(users)
  }, [users])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10  offset-xl-2 col-xl-8">
              <div className={styles.tableBigWrapper}>
                {isPendingGetUser ? (
                  <Spinner />
                ) : (
                  <table
                    className={`${styles.tableBig} ${styles.small}`}
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tr className={styles.thRow}>
                      {TITLE_NAMES.map((item) => (
                        <th key={item} onClick={() => sortReports(item)}>
                          <div className={styles.tableSortCol}>{item}</div>
                        </th>
                      ))}
                    </tr>
                    <tbody>
                      {sortNewUsers?.map((item: any) => {
                        const {
                          active,
                          avatar,
                          email = '',
                          id,
                          name,
                          role,
                        } = item

                        return (
                          <tr className={styles.bxShadow}>
                            <td className={styles.tdId}>{id}</td>
                            <td className={styles.tdMain}>
                              <a
                                className={styles.avatarLink}
                                onClick={() => handleEditUser(item)}
                              >
                                <img
                                  className={styles.avatarImg}
                                  src={avatar.sm || 'img/users/no-avatar.png'}
                                  alt=""
                                />
                                <span className={styles.name}>{name}</span>
                              </a>
                            </td>
                            <td className={styles.tdEmail}>{email || '-'}</td>
                            <td className={styles.tdAccess}>
                              {roles[role as keyof typeof roles]}
                            </td>
                            {active ? (
                              <td className={styles.tdStatus}>
                                <i
                                  className={`${styles.icon} ${styles.iconVerified22} ${styles.icon16}`}
                                />{' '}
                                Активен
                              </td>
                            ) : (
                              <td className={styles.tdStatus}>
                                <div
                                  className={classNames(
                                    styles.small,
                                    styles.colorRed
                                  )}
                                >
                                  Выключен
                                </div>
                              </td>
                            )}
                            <td className={styles.tdButtons}>
                              <EditButton
                                onClick={() => handleEditUser(item)}
                              />
                              <DeleteButton
                                onClick={() => handleDeleteUser(item)}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Employee
