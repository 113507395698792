import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axiosInstance'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'
import { URL } from '../../constants/url'

export interface Service {
  id: number
  car: {
    id: number
    name: string
  }
  user: {
    id: number
    name: string
  }
  images: {
    lg: string
    md: string
    original: string
    sm: string
  }[]
  date: string
  mileage: number | string
  description: string
  next_service_mileage: number | string
  currency: string;
  amount: string | number;
}

export interface ServicesState {
  currentService: number | null
  services: Array<Service>
  isVisibleModalService: boolean
  isPendingGetServices: boolean
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddService: boolean
  isFulfilledAddService: boolean
  isRejectAddService: boolean
  isVisibleDeleteService: boolean
}

const initialState: ServicesState = {
  currentService: null,
  services: [],
  isVisibleModalService: false,
  isPendingGetServices: true,
  isPendingAddService: false,
  isFulfilledAddService: false,
  isRejectAddService: false,
  isVisibleDeleteService: false,
}

export const fetchServices = createAsyncThunk(
  'services/fetchServices',
  async (carId: number, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/${URL.cars}/${carId}/services`)

      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

type FetchAddService = {
  date: string
  description: string
  mileage: string | number
  nextServiceMileage: string
  photos?: any
  id?: number | string
  amount?: number | string
  currency: string
}
export const fetchAddService = createAsyncThunk(
  'service/fetchAddService',
  async (
    {
      date,
      description,
      mileage,
      photos,
      nextServiceMileage,
      id,
      amount,
      currency
    }: FetchAddService,
    thunkAPI
  ) => {
    const {
      cars: { currentCar },
    } = thunkAPI.getState() as RootState
    const formData = new FormData()

    formData.append('date', date)
    formData.append('description', String(description))
    formData.append('mileage', String(mileage))
    formData.append('next_service_mileage', String(nextServiceMileage))
    formData.append('currency', currency)
    if (amount) formData.append('amount', String(amount))

    if (photos?.length > 0) {
      //@ts-ignore
      photos.forEach((photo, index) => {
        formData.append(`images[${index}]`, photo)
      })
    }
    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.cars}/${currentCar?.id}/services${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(setIsVisibleModalService(false))

        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteServices = createAsyncThunk(
  'services/fetchDeleteServices',
  async (_, thunkAPI) => {
    const {
      cars: { currentCar },
      service: { currentService },
    } = thunkAPI.getState() as RootState
    try {
      const response = await axiosInstance.delete(
        `/${URL.cars}/${currentCar?.id}/services/${currentService}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return { ...response.data, currentService }
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setCurrentService: (state, action) => {
      return { ...state, currentService: action.payload }
    },
    setIsVisibleModalService: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalService: action.payload,
      }
    },
    setIsVisibleDeleteService: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteService: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      return { ...state, isPendingGetServices: true }
    })
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      return { ...state, services: action.payload, isPendingGetServices: false }
    })
    builder.addCase(fetchServices.rejected, (state, action) => {
      toast.error(action.payload as string)

      return {
        ...state,
        isPendingGetServices: false,
        services: [],
      }
    })

    // FOR ADMIN
    builder.addCase(fetchAddService.pending, (state) => {
      return {
        ...state,
        setIsVisibleModalService: false,
        isPendingAddService: true,
        isFulfilledAddService: false,
        isRejectAddService: false,
      }
    })
    builder.addCase(fetchAddService.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      const isNoteExist = state.services.find(
        (service) => service.id === action.payload.id
      )

      if (isNoteExist) {
        return {
          ...state,
          isPendingAddService: false,
          isFulfilledAddService: true,
          isRejectAddService: false,
          services: state.services.map((service) =>
            service.id === action.payload.id ? action.payload : service
          ),
        }
      } else {
        return {
          ...state,
          isPendingAddService: false,
          isFulfilledAddService: true,
          isRejectAddService: false,
          services: [action.payload, ...current(state.services)],
        }
      }
    })

    builder
      .addCase(fetchAddService.rejected, (state, action) => {
        toast.error(action.payload as string)

        return {
          ...state,
          isPendingAddService: false,
          isFulfilledAddService: false,
          isRejectAddService: true,
          setIsVisibleModalService: false,
        }
      })
      .addCase(fetchDeleteServices.pending, (state) => {
        return {
          ...state,
          isPendingAddService: true,
          isVisibleDeleteService: false,
        }
      })
      .addCase(fetchDeleteServices.fulfilled, (state, action) => {
        toast.success('Удалено!')

        return {
          ...state,
          isPendingAddService: false,
          isVisibleDeleteService: false,
          services: state.services.filter(
            (item) => item.id !== action.payload.currentService
          ),
        }
      })
      .addCase(fetchDeleteServices.rejected, (state, action) => {
        toast.error(action.payload as string)

        return {
          ...state,
          isPendingAddService: false,
          isVisibleDeleteService: false,
        }
      })
  },
})

export const {
  setCurrentService,
  setIsVisibleDeleteService,
  setIsVisibleModalService,
} = servicesSlice.actions

export default servicesSlice.reducer
