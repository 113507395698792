import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { RootState } from '../store'
import { PATH } from '../../constants/path'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'

interface User {
  id: number
  name: string
  email: string
  avatar: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
  role: string
  login?: string
  phone: string
  phone2: string
  registration: string
  passport: {
    number: string
    issue_date: string
    issue_authority: string
    identification_number: string
  }
  drivers_license: {
    number: string
    issue_date: string
    issue_authority: string
  }
  drivers_card: {
    number: string
    issue_date: string
    issue_authority: string
  }
}

export interface UserState {
  currentUser: User | null
  users: Array<User>
  isVisibleModalUser: boolean
  isVisibleDeleteModalUser: boolean
  isPendingGetUser: boolean
  currentUserByRequest: User | null
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddUser: boolean
  isFulfilledAddUser: boolean
  isRejectAddUser: boolean
}

const initialState: UserState = {
  currentUser: null,
  isVisibleModalUser: false,
  isVisibleDeleteModalUser: false,
  users: [],
  isPendingGetUser: true,
  currentUserByRequest: null,
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddUser: false,
  isFulfilledAddUser: false,
  isRejectAddUser: false,
}

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (id: string | number, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.user}/${id}`
      )

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/${URL.user}`)

    if (response.statusText === 'OK') {
      return response
    }
    throw new Error()
  } catch (error) {
    console.error(error)
    throw error
  }
})

// GET USERS FOR ADMIN && POST USERS FOR ADMIN
export const fetchUsersForAdmin = createAsyncThunk(
  'users/fetchUsersForAdmin',
  async (id: string | number) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.company}/${id}/user`
      )

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

type FetchEditUser = {
  selectedImage: any
  login: string
  isActive: boolean
  name: string
  role: string
  email: string
  phone: string
  phone2: string
  password: string
  confirmPassword: string
  driversCardIssueDate: string
  driversCardIssueAuthority: string
  driversLicensedNumber: string
  driversLicensedIssueDate: string
  driversLicensedIssueAuthority: string
  driversCardNumber: string
  currentCompanyId?: string | number
  id?: number
  passportSerial: string
  passportNumber: string
  passportDate: string
  passportIssue: string
  passportRegistration: string
}

type fetchEditUserProfile = {
  name: string
  email: string
  password: string
  confirmPassword: string
  selectedImage: any
  id?: string
  login?: string
}

export const fetchEditUser = createAsyncThunk(
  'users/fetchEditUser',
  async (
    {
      selectedImage,
      login,
      isActive,
      name,
      role,
      email,
      phone,
      phone2,
      password,
      confirmPassword,
      passportSerial,
      passportNumber,
      passportDate,
      passportIssue,
      passportRegistration,
      driversCardNumber,
      driversCardIssueDate,
      driversCardIssueAuthority,
      driversLicensedNumber,
      driversLicensedIssueDate,
      driversLicensedIssueAuthority,

      id = undefined,
    }: FetchEditUser,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('active', isActive ? '1' : '0')
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('phone2', phone2)
    formData.append('login', login)
    if (password && confirmPassword) {
      formData.append('password', password)
      formData.append('password_confirmation', confirmPassword)
    }
    if (role) formData.append('role', role)
    if (selectedImage) {
      formData.append('avatar', selectedImage, selectedImage.name)
    }

    // PASSPORT
    formData.append('passport[number]', passportNumber)
    formData.append('passport[issue_date]', passportDate)
    formData.append('passport[issue_authority]', passportIssue)
    formData.append('passport[identification_number]', passportSerial)
    formData.append('registration', passportRegistration)

    // DRIVER
    formData.append('drivers_license[number]', driversLicensedNumber)
    formData.append('drivers_license[issue_date]', driversLicensedIssueDate)
    formData.append(
      'drivers_license[issue_authority]',
      driversLicensedIssueAuthority
    )

    formData.append('drivers_card[number]', driversCardNumber)
    formData.append('drivers_card[issue_date]', driversCardIssueDate)
    formData.append('drivers_card[issue_authority]', driversCardIssueAuthority)

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.user}${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchEditProfile = createAsyncThunk(
  'users/fetchEditProfile',
  async (
    {
      name,
      email,
      password,
      confirmPassword,
      selectedImage,
      id = undefined,
    }: fetchEditUserProfile,
    thunkAPI
  ) => {
    const formData = new FormData()

    formData.append('name', name)
    formData.append('email', email)

    if (password && confirmPassword) {
      formData.append('password', password)
      formData.append('password_confirmation', confirmPassword)
    }

    formData.set('_method', 'put')

    if (selectedImage) {
      formData.append('avatar', selectedImage, selectedImage.name)
    }

    try {
      const response = await axiosInstance.post(`/${URL.auth}/user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchAddUserForAdmin = createAsyncThunk(
  'users/fetchAddUserForAdmin',
  async (
    {
      currentCompanyId,
      name,
      email,
      password,
      confirmPassword,
      selectedImage,
      isActive,
      role,
      login,
    }: FetchEditUser,
    thunkAPI
  ) => {
    const formData = new FormData()

    formData.append('name', name)
    if (login) formData.append('login', login)
    formData.append('email', email)
    if (role) formData.append('role', role)
    formData.append('active', isActive ? '1' : '0')

    if (password && confirmPassword) {
      formData.append('password', password)
      formData.append('password_confirmation', confirmPassword)
    }

    if (selectedImage) {
      formData.append('avatar', selectedImage, selectedImage.name)
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.company}/${currentCompanyId}/user`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteUser = createAsyncThunk(
  'users/fetchDeleteUser',
  async (_, thunkAPI) => {
    const {
      users: { currentUser },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.user}/${currentUser?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      return { ...state, currentUser: action.payload }
    },
    setCurrentUserByRequest: (state, action) => {
      return { ...state, currentUserByRequest: action.payload }
    },
    setIsVisibleModalUser: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalUser: action.payload,
        currentUser: action.payload ? state.currentUser : null,
      }
    },

    setIsVisibleDeleteModalUser: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalUser: action.payload,
    }),
  },
  extraReducers: (builder) => {
    //GET owner
    builder.addCase(fetchUsers.pending, (state) => {
      return { ...state, isPendingGetUser: true }
    })
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      return { ...state, users: action.payload.data, isPendingGetUser: false }
    })
    builder.addCase(fetchUsers.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetUser: false }
    })

    // GET ONE USER
    builder.addCase(fetchUser.pending, (state) => {
      return { ...state, isPendingGetUser: true }
    })

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      return {
        ...state,
        currentUserByRequest: action.payload.data,
        isPendingGetUser: false,
      }
    })

    builder.addCase(fetchUser.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetUser: false }
    })
    //GET ADMIN
    builder.addCase(fetchUsersForAdmin.pending, (state) => {
      return { ...state, isPendingGetUser: true }
    })
    builder.addCase(fetchUsersForAdmin.fulfilled, (state, action) => {
      return { ...state, users: action.payload.data, isPendingGetUser: false }
    })
    builder.addCase(fetchUsersForAdmin.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetUser: false }
    })
    // EDIT
    builder.addCase(fetchEditUser.pending, (state) => {
      return {
        ...state,
        isPendingAddUser: true,
        isFulfilledAddUser: false,
        isRejectAddUser: false,
      }
    })
    builder.addCase(fetchEditUser.fulfilled, (state, action) => {
      toast.success('Сохранено!')
      if (window.location.pathname === PATH.PROFILE) {
        localStorage.setItem('userInfo', JSON.stringify(action.payload))
        return state
      }

      const isUserExist = current(state).users.find(
        (item) => item.id === action.payload.id
      )

      if (isUserExist) {
        return {
          ...state,
          isPendingAddUser: false,
          isFulfilledAddUser: true,
          isRejectAddUser: false,
          isVisibleModalUser: false,
          users: state.users.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        }
      }
      return {
        ...state,
        isPendingAddUser: false,
        isFulfilledAddUser: true,
        isRejectAddUser: false,
        users: [action.payload, ...state.users],
        isVisibleModalUser: false,
      }
    })
    builder.addCase(fetchEditUser.rejected, (state, action) => {
      toast.error(action.payload as string)

      return {
        ...state,
        isPendingAddUser: false,
        isFulfilledAddUser: false,
        isRejectAddUser: true,
      }
    })

    // EDIT PROFILE
    builder.addCase(fetchEditProfile.pending, (state) => {
      return state
    })
    builder.addCase(fetchEditProfile.fulfilled, (state, action) => {
      toast.success('Сохранено!')
      if (window.location.pathname === PATH.PROFILE) {
        localStorage.setItem('userInfo', JSON.stringify(action.payload))
        return state
      }
    })
    builder.addCase(fetchEditProfile.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
    // ADD FOR ADMIN
    builder.addCase(fetchAddUserForAdmin.pending, (state) => {
      return state
    })
    builder.addCase(fetchAddUserForAdmin.fulfilled, (state, action) => {
      const isUserExist = current(state).users.find(
        (item) => item.id === action.payload.id
      )
      toast.success('Сохранено!')
      if (window.location.pathname === PATH.PROFILE) {
        localStorage.setItem('userInfo', JSON.stringify(action.payload))
      }

      if (isUserExist) {
        return {
          ...state,
          isVisibleModalUser: false,
          users: state.users.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        }
      }
      return {
        ...state,
        users: [action.payload, ...state.users],
        isVisibleModalUser: false,
      }
    })
    builder.addCase(fetchAddUserForAdmin.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
    // DELETE
    builder.addCase(fetchDeleteUser.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteUser.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      return {
        ...state,
        isVisibleDeleteModalUser: false,
        currentUser: null,
        users: state.users.filter((user) => user.id !== state?.currentUser?.id),
      }
    })
    builder.addCase(fetchDeleteUser.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
  },
})

export const {
  setCurrentUser,
  setIsVisibleModalUser,
  setIsVisibleDeleteModalUser,
  setCurrentUserByRequest,
} = usersSlice.actions

export default usersSlice.reducer
