import classNames from 'classnames'
import styles from './AddClientModal.module.css'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { getRole } from '../../../utils/getRole'
//@ts-ignore
import ModalImage from 'react-modal-image'
import {
  Client,
  fetchEditClient,
  setIsVisibleModalClient,
} from '../../../redux/reducers/clients'
import { clientsSelector } from '../../../redux/selectors/clients-selector'
import {
  fetchAddPhones,
  fetchDeletePhones,
  fetchPhones,
  Phone,
} from '../../../redux/reducers/phones'
import { phonesSelector } from '../../../redux/selectors/phones-selector'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { IMAGE_TYPE } from '../../../constants/file-types'
import LoadingPage from '../../loading/LoadingPage'

const { isAdmin, isOwner } = getRole()

const roles = [
  { role: 'Водитель', value: 'driver' },
  { role: 'Помощник', value: 'assistant' },
  { role: 'Админ', value: 'admin' },
]

const MainInfo = ({
  isActive,
  setIsActive,
  district,
  setDistrict,
  address,
  setAddress,
  entity,
  setEntity,
  entityName,
  setEntityName,
  name,
  setName,
  email,
  setEmail,
  phones,
  setPhones,
  countryIso,
  setCountryIso,
  countryName,
  setCountryName,
  cityId,
  setCityId,
  cityName,
  setCityName,
  logo,
  handleImageChange,
  errors,
  setErrors,
  contracts,
}: any) => {
  const dispatch = useDispatch<AppDispatch>()

  const [newNumber, setNewNumber] = useState('')
  const [newName, setNewName] = useState('')
  const [newEmail, setNewEmail] = useState('')

  const [newJob, setNewJob] = useState('')
  const [newNotification, setNewNotification] = useState(false)
  const [isVisibleAddPhone, setIsVisibleAddPhone] = useState(false)

  const [isWasChanged, setIsWasChanged] = useState<null | number>(null)

  const handleSelectChangeCountry = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCountryIso(event.target.value)

    if (event.target.value === 'BY') {
      setCountryName('Беларусь')
    } else if (event.target.value === 'RU') {
      setCountryName('Россия')
    } else {
      setCountryName('')
    }
  }

  const handleSelectChangeCity = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target.value === '1') {
      setCityId(event.target.value)

      setCityName('Москва')
    } else if (event.target.value === '2') {
      setCityId(event.target.value)

      setCityName('Брест')
    } else {
      setCityId(undefined)

      setCityName(undefined)
    }
  }

  const handleChange = (event: { target: { value: string } }) => {
    setEntity(event.target.value !== 'individual')
  }

  const handleNumberChange = (id: number, newNumber: string) => {
    setIsWasChanged(id)
    setPhones(
      phones.map((phone: Phone) =>
        phone.id === id ? { ...phone, number: newNumber } : phone
      )
    )
  }

  const handleNameChange = (id: number, newName: string) => {
    setIsWasChanged(id)

    setPhones(
      phones.map((phone: Phone) =>
        phone.id === id ? { ...phone, name: newName } : phone
      )
    )
  }

  const handleEmailChange = (id: number, newEmail: string) => {
    setIsWasChanged(id)

    setPhones(
      phones.map((phone: Phone) =>
        phone.id === id ? { ...phone, email: newEmail } : phone
      )
    )
  }

  const handleJobChange = (id: number, newJob: string) => {
    setIsWasChanged(id)

    setPhones(
      phones.map((phone: Phone) =>
        phone.id === id ? { ...phone, job: newJob } : phone
      )
    )
  }

  const handleNotificationChange = (id: number, newNotification: boolean) => {
    setIsWasChanged(id)

    setPhones(
      phones.map((phone: Phone) =>
        phone.id === id ? { ...phone, notification: newNotification } : phone
      )
    )
  }

  const handleAddPhone = () => {
    dispatch(
      fetchAddPhones({
        name: newName,
        email: newEmail,
        number: newNumber,
        notification: newNotification,
        job: newJob,
      })
    )

    setNewNumber('')
    setNewName('')
    setNewJob('')
    setNewEmail('')
    setNewNotification(false)
    setIsVisibleAddPhone(false)
  }

  const handleEditPhone = (item: Phone) => {
    dispatch(fetchAddPhones({ ...item }))
  }

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value)
      //@ts-ignore
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: !!e.target.value ? false : true,
      }))
    }

  return (
    <>
      <div className="mb-5">
        <div className="row mb-3">
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
            <div className={styles.loadPhoto}>
              <div
                className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
              >
                <div className={`${styles.profileAvatar} mb-1`}>
                  <ModalImage
                    className={`${styles.profileAvatar} mb-1`}
                    small={logo || 'img/users/no-avatar.png'}
                    alt="User Photo"
                  />
                </div>

                <span className={styles.small}>Загрузить фото</span>

                <input
                  type="file"
                  accept={IMAGE_TYPE}
                  name="photo"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleImageChange(event)
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-3">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 small">
                <input
                  type="radio"
                  name="client_type"
                  id="client_type_1"
                  value="individual"
                  checked={!entity}
                  onChange={handleChange}
                />
                <label className="mr-3" htmlFor="client_type_1">
                  Физлицо
                </label>
                <input
                  type="radio"
                  name="client_type"
                  id="client_type_2"
                  value="legal"
                  checked={entity}
                  onChange={handleChange}
                />
                <label className="mr-3" htmlFor="client_type_2">
                  Юрлицо
                </label>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 jsUrlico">
                <small className={classNames('mb-1', 'd-block', styles.small2)}>
                  Наименование *
                </small>
                <input
                  value={entityName}
                  onChange={handleInputChange(setEntityName, 'entityName')}
                  required
                  type="text"
                  placeholder="Наименование"
                  className={errors.entityName ? styles.isError : ''}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <small className={classNames('mb-1', 'd-block', styles.small2)}>
                  ФИО *
                </small>
                <input
                  value={name}
                  onChange={handleInputChange(setName, 'name')}
                  required
                  type="text"
                  placeholder="ФИО"
                  className={errors.name ? styles.isError : ''}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <small className={classNames('mb-1', 'd-block', styles.small2)}>
                  E-mail
                </small>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  placeholder="E-mail"
                />
              </div>
            </div>

            <div className={styles.small}>
              <input
                id="client_enable"
                checked={isActive}
                type="checkbox"
                onChange={(e) => {
                  setIsActive(e.target.checked)
                }}
                name="client_enable"
                className={styles.input}
              />
              <label htmlFor="client_enable">Активен</label>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Договор
            </small>
            <select>
              {contracts.map(
                //@ts-ignore
                (contract) => (
                  <option>
                    {contract.number} от {contract.date}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <div className="mb-2">
              <b>Адрес</b>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Страна *
            </small>
            <select
              className={errors.countryIso ? styles.isError : ''}
              value={countryIso}
              onChange={(e) => {
                handleSelectChangeCountry(e)
                // @ts-ignore
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  countryIso: false,
                }))
              }}
              required
            >
              <option value="">Выберите страну</option>
              <option value="BY">Беларусь</option>
              <option value="RU">Россия</option>
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Город *
            </small>
            <select
              className={errors.cityId ? styles.isError : ''}
              value={cityId}
              onChange={(e) => {
                handleSelectChangeCity(e)
                // @ts-ignore
                setErrors((prevErrors) => ({ ...prevErrors, cityId: false }))
              }}
            >
              <option value={undefined}>Выберите город</option>
              <option value="2">Брест</option>
              <option value="1">Москва</option>
            </select>
          </div>

          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Район *
            </small>
            <input
              className={errors.district ? styles.isError : ''}
              value={district}
              onChange={handleInputChange(setDistrict, 'district')}
              required
              type="text"
              placeholder="Район города"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <small className={classNames('mb-1', 'd-block', styles.small2)}>
              Адрес *
            </small>
            <input
              className={errors.address ? styles.isError : ''}
              value={address}
              onChange={handleInputChange(setAddress, 'address')}
              required
              type="text"
              placeholder="Адрес загрузки/отгрузки"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="mb-2">
            <b>Телефоны</b>
          </div>
          {phones.map((item: Phone) => (
            <div
              className={classNames(
                'row align-items-center pl-2 pr-2 pt-2 pb-2 mb-4',
                styles.small3,
                styles.bxShadowSmall,
                styles.bgGray,
                styles.borderRadius5
              )}
            >
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  value={item.number || ''}
                  onChange={(e) => handleNumberChange(item.id, e.target.value)}
                  required
                  type="text"
                  placeholder="Телефон"
                />
              </div>
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  type="email"
                  value={item.email || ''}
                  onChange={(e) => handleEmailChange(item.id, e.target.value)}
                  placeholder="E-mail"
                />
              </div>
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  type="text"
                  value={item.name || ''}
                  onChange={(e) => handleNameChange(item.id, e.target.value)}
                  placeholder="Имя"
                />
              </div>
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  type="text"
                  value={item.job || ''}
                  onChange={(e) => handleJobChange(item.id, e.target.value)}
                  placeholder="Должность"
                />
              </div>
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                {isWasChanged === item.id && (
                  <button
                    onClick={() => handleEditPhone(item)}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,

                      styles.btnTrans,
                      styles.btnBlueOutline,
                      'mr-1'
                    )}
                    disabled={!item.number}
                  >
                    <i
                      className={classNames(
                        styles.icon,
                        styles.iconVerified2,
                        styles.iconBlue
                      )}
                    ></i>{' '}
                    Сохранить
                  </button>
                )}
              </div>
              <div
                className="col-6 pt-1 pl-1 pr-1"
                style={{ textAlign: 'right' }}
              >
                <input
                  type="checkbox"
                  id={`phone_sms_${item.id}`}
                  checked={item.notification}
                  onChange={(e) =>
                    handleNotificationChange(item.id, e.target.checked)
                  }
                />
                <label
                  className={styles.labelNotifications}
                  htmlFor={`phone_sms_${item.id}`}
                >
                  Уведомления
                </label>
                <div
                  onClick={() => dispatch(fetchDeletePhones(item.id))}
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnIcon,
                    styles.btnOutline
                  )}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.icon15,
                      styles.iconDelete
                    )}
                  />
                </div>
              </div>
            </div>
          ))}
          {/* {phones.map((item: Phone) => (
            <div className="row align-items-center pl-2 pr-2 small-2 mb-3">
              <div
                className="order-1 order-sm-1 col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  value={item.number || ''}
                  onChange={(e) => handleNumberChange(item.id, e.target.value)}
                  required
                  type="text"
                  placeholder="Телефон"
                />
              </div>
              <div
                className="order-2 order-sm-2 col-6 col-sm-4 col-md-4 col-lg-4 col-xl-5 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  type="text"
                  value={item.name || ''}
                  onChange={(e) => handleNameChange(item.id, e.target.value)}
                  placeholder="Имя"
                />
              </div>
              <div
                className="order-3 order-sm-3 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 pt-1 pl-1 pr-1"
                style={{ textAlign: 'left' }}
              >
                <input
                  type="text"
                  value={item.job || ''}
                  onChange={(e) => handleJobChange(item.id, e.target.value)}
                  placeholder="Должность"
                />
              </div>
              <div
                className="order-5 order-sm-4 col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 pt-1 pl-1 pr-1"
                style={{ textAlign: 'right' }}
              >
                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnIcon,
                    styles.btnBlueOutline,
                    styles.iconCheckWhite
                  )}
                  onClick={() => handleEditPhone(item)}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.iconCheckWhite,
                      styles.savePhoneCheck
                    )}
                  />{' '}
                </div>
                <div
                  onClick={() => dispatch(fetchDeletePhones(item.id))}
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnIcon,
                    styles.btnOutline
                  )}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.icon15,
                      styles.iconDelete
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center order-4 order-sm-5 col-4 pt-1 pl-1 pr-1">
                <input
                  type="checkbox"
                  id={`phone_sms_${item.id}`}
                  checked={item.notification}
                  onChange={(e) =>
                    handleNotificationChange(item.id, e.target.checked)
                  }
                />
                <label
                  className={styles.labelNotifications}
                  htmlFor={`phone_sms_${item.id}`}
                >
                  Уведомления
                </label>
              </div>
            </div>
          ))} */}

          {isVisibleAddPhone && (
            <div>
              <div
                className={classNames(
                  'row align-items-center pl-2 pr-2 pt-2 pb-2 mb-4',
                  styles.small3,
                  styles.bxShadowSmall,
                  styles.bgGray,
                  styles.borderRadius5
                )}
              >
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    value={newNumber}
                    onChange={(e) => setNewNumber(e.target.value)}
                    required
                    type="text"
                    placeholder="Телефон"
                  />
                </div>
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder="E-mail"
                  />
                </div>
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    placeholder="Имя"
                  />
                </div>
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    type="text"
                    value={newJob}
                    onChange={(e) => setNewJob(e.target.value)}
                    placeholder="Должность"
                  />
                </div>
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={handleAddPhone}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,
                      styles.btnTrans,
                      styles.btnBlueOutline,
                      'mr-1'
                    )}
                    disabled={!newNumber}
                  >
                    <i
                      className={classNames(
                        styles.icon,
                        styles.iconVerified2,
                        styles.iconBlue
                      )}
                    ></i>{' '}
                    Добавить
                  </button>
                </div>
                <div
                  className="col-6 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'right' }}
                >
                  <input
                    type="checkbox"
                    id={`new_phone_sms`}
                    checked={newNotification}
                    onChange={(e) => setNewNotification(e.target.checked)}
                  />
                  <label
                    className={styles.labelNotifications}
                    htmlFor={`new_phone_sms`}
                  >
                    Уведомления
                  </label>
                </div>
              </div>

              {/* <div className="row align-items-center pl-2 pr-2 small-2 mb-3">
                <div
                  className="order-1 order-sm-1 col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    value={newNumber}
                    onChange={(e) => setNewNumber(e.target.value)}
                    type="text"
                    placeholder="Телефон"
                  />
                </div>
                <div
                  className="order-2 order-sm-2 col-6 col-sm-4 col-md-4 col-lg-4 col-xl-5 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    placeholder="Имя"
                  />
                </div>
                <div
                  className="order-3 order-sm-3 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'left' }}
                >
                  <input
                    type="text"
                    value={newJob}
                    onChange={(e) => setNewJob(e.target.value)}
                    placeholder="Должность"
                  />
                </div>
                <div
                  className="order-5 order-sm-4 col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 pt-1 pl-1 pr-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,
                      styles.btnIcon,
                      styles.btnOutline
                    )}
                    onClick={() => setIsVisibleAddPhone(false)}
                  >
                    <i
                      className={classNames(
                        styles.icon,
                        styles.icon15,
                        styles.iconDelete
                      )}
                    />
                  </div>
                </div>
                <div className="order-4 order-sm-5 col-5 pt-1 pl-1 pr-1">
                  <input
                    type="checkbox"
                    id={`phone_sms_new`}
                    checked={newNotification}
                    onChange={(e) => setNewNotification(e.target.checked)}
                  />
                  <label htmlFor={`phone_sms_new`}>Уведомления</label>
                </div>
              </div>

              <div
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue,
                  'mr-2',
                  'mb-3'
                )}
                onClick={handleAddPhone}
              >
                <i className={classNames(styles.icon, styles.iconCheckWhite)} />{' '}
                Добавить
              </div> */}
            </div>
          )}

          <button
            onClick={(e) => {
              e.preventDefault()
              setIsVisibleAddPhone((prev) => !prev)
            }}
            className={`${styles.btn} ${styles.btnSmall} ${styles.btnBlueOutline}`}
            // disabled={true}
          >
            {!isVisibleAddPhone ? '+ Добавить' : 'Отмена'}
          </button>
        </div>
      </div>
    </>
  )
}

const Requisites = ({
  requisitesInn,
  setRequisitesInn,
  requisitesBankBik,
  setRequisitesBankBik,
  requisitesBankSwift,
  setRequisitesBankSwift,
  requisitesBankAccount,
  setRequisitesBankAccount,
  requisitesBankName,
  setRequisitesBankName,
  requisitesAddress,
  setRequisitesAddress,
}: any) => {
  const textareaRef = useRef(null)

  useEffect(() => {}, [])

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [requisitesAddress])

  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-9 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Номер счета
          </small>
          <input
            value={requisitesBankAccount}
            onChange={(e) => setRequisitesBankAccount(e.target.value)}
            type="text"
            placeholder="Номер счета"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            УНП/ИНН
          </small>
          <input
            value={requisitesInn}
            onChange={(e) => setRequisitesInn(e.target.value)}
            type="text"
            placeholder="УНП/ИНН"
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Банк
          </small>
          <input
            value={requisitesBankName}
            onChange={(e) => setRequisitesBankName(e.target.value)}
            type="text"
            placeholder="Название банка"
          />
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            SWIFT
          </small>
          <input
            value={requisitesBankSwift}
            onChange={(e) => setRequisitesBankSwift(e.target.value)}
            type="text"
            placeholder="SWIFT"
          />
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            БИК
          </small>
          <input
            value={requisitesBankBik}
            onChange={(e) => setRequisitesBankBik(e.target.value)}
            type="text"
            placeholder="БИК"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Юридический адрес
          </small>
          <textarea
            className={styles.rangeValue}
            ref={textareaRef}
            value={requisitesAddress}
            onChange={(e) => setRequisitesAddress(e.target.value)}
            placeholder="Юридический адрес"
          />
        </div>
      </div>
    </div>
  )
}

function AddClientModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentClientByRequest } = useSelector(clientsSelector)
  const { phones: phonesByRequest } = useSelector(phonesSelector)
  useClearBodyNoScrollClass()

  // MAIN INFO

  const [isActive, setIsActive] = useState(
    currentClientByRequest?.active || false
  )
  const [district, setDistrict] = useState('')
  const [address, setAddress] = useState('')
  const [entity, setEntity] = useState(false)
  const [entityName, setEntityName] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phones, setPhones] = useState<Array<Phone>>([])
  const [countryIso, setCountryIso] = useState('')
  const [countryName, setCountryName] = useState('')
  const [cityId, setCityId] = useState<number | string>('')
  const [cityName, setCityName] = useState('')
  const [logo, setLogo] = useState<null | string>(null)
  const [contracts, setContracts] = useState<Client['contracts']>([])

  const [requisitesInn, setRequisitesInn] = useState('')
  const [requisitesBankBik, setRequisitesBankBik] = useState('')
  const [requisitesBankSwift, setRequisitesBankSwift] = useState('')
  const [requisitesBankAccount, setRequisitesBankAccount] = useState('')
  const [requisitesBankName, setRequisitesBankName] = useState('')
  const [requisitesAddress, setRequisitesAddress] = useState('')

  const [currentTab, setCurrentTab] = useState(1)

  const [selectedImage, setSelectedImage] = useState<any>(null)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setSelectedImage(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setLogo(imageUrl)
    }
  }

  useEffect(() => {
    if (currentClientByRequest) {
      const {
        requisites: {
          inn,
          bank_account,
          bank_bik,
          bank_name,
          bank_swift,
          address,
        },
        active,
        district,
        address: addressMain,
        entity,
        entity_name,
        name,
        email,
        country: { iso, name: countryName },
        city: { id: cityId, name: cityName },
        logo,
        contracts,
      } = currentClientByRequest

      // MAIN info
      setIsActive(active)
      setDistrict(district)
      setAddress(addressMain)
      setEntity(entity)
      setEntityName(entity_name)
      setName(name)
      setEmail(email)
      setContracts(contracts)
      setCountryIso(iso)
      setCountryName(countryName)
      setCityId(cityId)
      setCityName(cityName)
      setLogo(logo.original)
      // Requisites
      setRequisitesInn(inn)
      setRequisitesBankBik(bank_bik)
      setRequisitesBankSwift(bank_swift)
      setRequisitesBankAccount(bank_account)
      setRequisitesBankName(bank_name)
      setRequisitesAddress(address)
    }
  }, [])

  useEffect(() => {
    setPhones(phonesByRequest)
  }, [phonesByRequest])
  const handleSaveClient = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const commonPayload = {
      isActive,
      district,
      address,
      entity,
      entityName,
      name,
      email,
      phones,
      countryIso,
      countryName,
      cityId,
      cityName,
      logo: selectedImage,
      requisitesInn,
      requisitesBankBik,
      requisitesBankSwift,
      requisitesBankAccount,
      requisitesBankName,
      requisitesAddress,
    }

    const newErrors = {
      entityName: !entityName,
      name: !name,
      countryIso: !countryIso,
      countryName: !countryName,
      cityId: !cityId,
      cityName: !cityName,
      district: !district,
      address: !address,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      if (currentClientByRequest) {
        dispatch(
          fetchEditClient({ ...commonPayload, id: currentClientByRequest.id })
        )
      } else {
        dispatch(fetchEditClient(commonPayload))
      }
    }
  }

  const renderComponent = () => {
    const mainInfoProps = {
      isActive,
      setIsActive,
      district,
      setDistrict,
      address,
      setAddress,
      entity,
      setEntity,
      entityName,
      setEntityName,
      name,
      setName,
      email,
      setEmail,
      phones,
      setPhones,
      countryIso,
      setCountryIso,
      countryName,
      setCountryName,
      cityId,
      setCityId,
      cityName,
      setCityName,
      logo,
      setLogo,
      selectedImage,
      setSelectedImage,
      handleImageChange,
      errors,
      setErrors,
      contracts,
    }

    const requisitesProps = {
      requisitesInn,
      setRequisitesInn,
      requisitesBankBik,
      setRequisitesBankBik,
      requisitesBankSwift,
      setRequisitesBankSwift,
      requisitesBankAccount,
      setRequisitesBankAccount,
      requisitesBankName,
      setRequisitesBankName,
      requisitesAddress,
      setRequisitesAddress,
    }

    switch (currentTab) {
      case 1:
        return <MainInfo {...mainInfoProps} />

      case 2:
        return <Requisites {...requisitesProps} />

      default:
        return <MainInfo {...mainInfoProps} />
    }
  }

  useEffect(() => {
    if (currentClientByRequest && currentClientByRequest.id) {
      dispatch(fetchPhones(currentClientByRequest.id))
    }
  }, [])

  // HANDLE ERROR
  const [errors, setErrors] = useState({
    entityName: false,
    name: false,
    countryIso: false,
    countryName: false,
    cityId: false,
    cityName: false,
    district: false,
    address: false,
  })

  const checkValidation = () => {
    const newErrors = {
      entityName: !entityName,
      name: !name,
      countryIso: !countryIso,
      countryName: !countryName,
      cityId: !cityId,
      cityName: !cityName,
      district: !district,
      address: !address,
    }

    setErrors(newErrors)
  }

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalClient(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`mb-5 ${styles.h3}}`)}
            >
              {currentClientByRequest
                ? entity
                  ? currentClientByRequest.entity_name
                  : currentClientByRequest.name
                : 'Новый клиент'}
            </h3>
            <div
              className={classNames('row align-items-end', styles.radioTabs)}
            >
              <div
                className={classNames(
                  styles.menuButton,
                  currentTab === 1 ? styles.menuButtonActive : '',
                  'col-6 col-sm-4 col-md-4 mt-2 mb-2'
                )}
                onClick={() => setCurrentTab(1)}
              >
                Основное
              </div>
              <div
                className={classNames(
                  styles.menuButton,
                  currentTab === 2 ? styles.menuButtonActive : '',
                  'col-6 col-sm-4 col-md-4 mt-2 mb-2'
                )}
                onClick={() => setCurrentTab(2)}
              >
                Реквизиты
              </div>
            </div>
            <form onSubmit={handleSaveClient}>
              {renderComponent()}
              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={checkValidation}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                    type="submit"
                    // disabled={isDisabledButton}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    Сохранить
                  </button>
                </div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalClient(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddClientModal
