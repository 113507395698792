import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { AxiosError } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { getRole } from '../../utils/getRole'

export interface User {
  id: number
  name: string
  email: string
  avatar: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
  role: string
}

export interface Car {
  id: number
  name: string
  active: boolean
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
}

export interface Receipts {
  [key: string]: string[]
}

export interface InitState {
  isPendingGetReports: boolean
  isVisibleModalReport: boolean
  isVisibleDeleteModalReport: boolean
  currentReport: any
  reports: {
    id: number
    user: User
    car: Car
    date_from: string
    date_to: string
    distance: number
    cost: number
    receipts: Receipts
    editable: boolean
  }[]
}

const initialState: InitState = {
  isPendingGetReports: true,
  isVisibleModalReport: false,
  isVisibleDeleteModalReport: false,
  currentReport: null,
  reports: [],
}

interface ReportsQuery {
  cars?: string[]
  employees?: string[]
  dateFrom?: Date | string
  dateTo?: Date | string
  orderBy?: string
  orderDirection?: string
}

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async (
    {
      cars,
      employees,
      dateFrom,
      dateTo,
      orderBy,
      orderDirection,
    }: ReportsQuery,
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams()

    const formatDate = (date: Date | string) => {
      const newDate = new Date(date)
      const formattedDate = newDate
        .toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'Europe/Moscow',
        })
        .split('.')
        .reverse()
        .join('-')
      return formattedDate
    }

    //@ts-ignore
    const formattedDateFrom = formatDate(dateFrom)
    //@ts-ignore
    const formattedDateTo = formatDate(dateTo)

    if (cars) cars.forEach((car) => params.append('cars[]', car))
    if (employees)
      employees.forEach((employee) => params.append('employees[]', employee))
    if (dateFrom) params.append('date_from', formattedDateFrom)
    if (dateTo) params.append('date_to', formattedDateTo)
    orderBy
      ? params.append('order_by', orderBy)
      : params.append('order_by', 'date_from')
    orderDirection
      ? params.append('order_direction', orderDirection)
      : params.append('order_direction', 'desc')

    try {
      const response = await axiosInstance.get(`/${URL.report}`, { params })

      if (response.statusText === 'OK') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)



export const fetchAddReports = createAsyncThunk(
  'reports/fetchAddReports',
  async (
    {
      truck,
      startTime,
      endTime,
      expenses,
      route,
      date,
      photos,
      id,
      receiptRemove,
    }: any,
    { rejectWithValue }
  ) => {
    const item = localStorage.getItem('userInfo')
    const userInfo = item ? JSON.parse(item) : {}

    const formData = new FormData()
    formData.append('user_id', userInfo?.id)
    formData.append('car_id', truck)
    formData.append('date_from', `${date}T${startTime}:00`)
    formData.append('date_to', `${date}T${endTime}:00`)
    if (route) formData.append('distance', route)
    if (expenses) formData.append('cost', expenses)

    if (photos?.length > 0) {
      //@ts-ignore
      photos.forEach((photo, index) => {
        formData.append(`receipt[${index}]`, photo)
      })
    }

    if (receiptRemove?.length > 0) {
      //@ts-ignore
      receiptRemove.forEach((RR, index) => {
        formData.append(`receipt_remove[${index}]`, RR)
      })
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.report}${id ? `/${id}` : ''}`,
        formData
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data.message)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchReportFile = createAsyncThunk(
  'reports/fetchReportFile',
  async (
    { dateFrom, dateTo, exportFormat, getCars, getEmployees }: any,
    { rejectWithValue, getState }
  ) => {
    try {
      const params = new URLSearchParams()

      params.append('date_from', dateFrom)
      params.append('date_to', dateTo)
      params.append('export', exportFormat)

      getCars.forEach((car: string) => {
        params.append('cars[]', car)
      })

      getEmployees.forEach((employee: string) => {
        params.append('employees[]', employee)
      })

      let response

      const { isAdmin } = getRole()
      if (isAdmin) {
        const {
          company: { currentCompany },
        } = getState() as RootState

        response = await axiosInstance.get(
          `/${URL.company}/${currentCompany?.id}/report-export?${params}`,
          {
            responseType: 'blob',
          }
        )
      } else {
        response = await axiosInstance.get(`/api/report-export?${params}`, {
          responseType: 'blob',
        })
      }

      if (response.statusText === 'OK') {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `report.${exportFormat}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
        return response.data
      }

      throw new Error('Ошибка при получении файла')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDeleteReport = createAsyncThunk(
  'reports/fetchDeleteReport',
  async (_, thunkAPI) => {
    const {
      reports: { currentReport },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.report}/${currentReport?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setCurrentReport: (state, action) => {
      return { ...state, currentReport: action.payload }
    },
    setIsVisibleModalReport: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalReport: action.payload,
        currentReport: action.payload ? state.currentReport : null,
      }
    },
    setIsVisibleDeleteModalReport: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalReport: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state, action) => {
        return { ...state, isPendingGetReports: true }
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        return {
          ...state,
          reports: action.payload,
          isPendingGetReports: false,
        }
      })
      .addCase(fetchReports.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingGetReports: false }
      })
      .addCase(fetchAddReports.pending, (state, action) => {
        return state
      })
      .addCase(fetchAddReports.fulfilled, (state, action) => {
        toast.success('Сохранено!')
        const isUserExist = current(state).reports.find(
          (item) => item.id === action.payload.id
        )

        if (isUserExist) {
          return {
            ...state,
            isVisibleModalReport: false,
            reports: state.reports.map((item) =>
              item.id === action.payload.id ? action.payload : item
            ),
          }
        }
        return {
          ...state,
          reports: [action.payload, ...state.reports],
          isVisibleModalReport: false,
        }
      })
      .addCase(fetchAddReports.rejected, (state, action) => {
        toast.error(action.payload as string)
        return {
          ...state,
          isVisibleModalReport: false,
        }
      })
      .addCase(fetchReportFile.pending, (state, action) => {
        return state
      })
      .addCase(fetchReportFile.fulfilled, (state, action) => {
        return state
      })
      .addCase(fetchReportFile.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })

    builder.addCase(fetchDeleteReport.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteReport.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      return {
        ...state,
        isVisibleDeleteModalReport: false,
        currentReport: null,
        reports: state.reports.filter(
          (item) => item.id !== state?.currentReport?.id
        ),
      }
    })
    builder.addCase(fetchDeleteReport.rejected, (state, action) => {
      toast.error(action.payload as string)
      return { ...state, isVisibleDeleteModalReport: false }
    })
  },
})

export const {
  setCurrentReport,
  setIsVisibleModalReport,
  setIsVisibleDeleteModalReport,
} = reportsSlice.actions

export default reportsSlice.reducer
