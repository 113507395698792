import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import styles from './PartModal.module.css'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { partsSelector } from '../../../redux/selectors/parts-selector'
import {
  addPart,
  editPart,
  fetchParts,
  setIsVisibleModalPart,
} from '../../../redux/reducers/parts'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { ListDropdown } from './ListDropDown'

const MainInfo = ({
  name,
  setName,
  number,
  setNumber,
  amount,
  setAmmount,
  currency,
  setCurrency,
  errors,
  setErrors,
  checkedCars,
  setCheckedCars,
}: any) => {
  const { cars } = useSelector(carsSelector)

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(`field = ${field}`)
      let value = e.target.value
      if (field == 'ammount') {
        value = value.replace(',', '.')
        value = value.replace(/[^0-9.]/g, '')
        const parts = value.split('.')
        if (parts.length === 2) {
          value = `${parts[0]}.${parts[1].slice(0, 2)}`
        }
      }
      setter(value)
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [field]: !!value ? false : true,
      }))
    }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrency(value)
  }

  const handleCheckedItems = (checkedItems = []) => {
    setCheckedCars(checkedItems)
  }

  return (
    <div>
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
          <div
            className={classNames(
              styles.small2,
              styles.small,
              styles.body,
              'd-block mb-1'
            )}
          >
            Наименование*
          </div>
          <input
            value={name}
            onChange={handleInputChange(setName, 'name')}
            required
            type="text"
            placeholder="Название запчасти"
            className={classNames({ isError: errors.field1 }, styles.input)}
          />
        </div>

        <div
          className={classNames(
            'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3'
          )}
        >
          <div
            className={classNames(
              styles.small2,
              styles.small,
              styles.body,
              'd-block mb-1'
            )}
          >
            Для машин
          </div>
          <ListDropdown
            data={cars}
            checkedItems={handleCheckedItems}
            checkedIds={checkedCars}
            allItemsName={'Подходит для всех'}
          />
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
          <small className={classNames(styles.small2, 'd-block mb-1')}>
            Номер
          </small>
          <input
            value={number}
            onChange={handleInputChange(setNumber, 'number')}
            required
            type="text"
            placeholder="Номер запчасти"
            className={errors.field2 ? 'isError' : ''}
          />
        </div>

        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
          <div className="mb-3">
            <small className={classNames(styles.small2, 'd-block mb-1')}>
              Стоимость
            </small>
            <input
              value={amount === 0 ? '' : amount}
              onChange={handleInputChange(setAmmount, 'ammount')}
              required
              type="text"
              placeholder="Сумма"
              className={errors.field3 ? 'isError' : ''}
            />
            <div
              className={classNames(
                styles.radioTabs,
                styles.small3,
                'd-flex flex-wrap justify-content-start'
              )}
              style={{ textAlign: 'left' }}
            >
              <div className="mt-1">
                <input
                  name="currency"
                  id="currency_radio4"
                  type="radio"
                  value="EUR"
                  checked={currency === 'EUR'}
                  onChange={handleCurrencyChange}
                />
                <label className="ml-2 mr-0" htmlFor="currency_radio4">
                  EUR
                </label>
              </div>
              <div className="mt-1">
                <input
                  name="currency"
                  id="currency_radio5"
                  type="radio"
                  value="RUB"
                  checked={currency === 'RUB'}
                  onChange={handleCurrencyChange}
                />
                <label className="ml-2 mr-0" htmlFor="currency_radio5">
                  RUB
                </label>
              </div>
              <div className="mt-1">
                <input
                  name="currency"
                  id="currency_radio6"
                  type="radio"
                  value="BYN"
                  checked={currency === 'BYN'}
                  onChange={handleCurrencyChange}
                />
                <label className="ml-2 mr-0" htmlFor="currency_radio6">
                  BYN
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function PartModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentPart } = useSelector(partsSelector)

  useClearBodyNoScrollClass()
  const [id, setPartId] = useState(-1)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [amount, setAmmount] = useState<number>(0)
  const [currency, setCurrency] = useState('BYN')
  const [checkedCars, setCheckedCars] = useState<number[]>([])

  const [isDisabledButton, setIsDisabledButton] = useState(true)

  useEffect(() => {
    if (currentPart) {
      setPartId(currentPart?.id)
      setName(currentPart?.name)
      setNumber(currentPart?.number.toString())
      setAmmount(currentPart?.amount)
      setCurrency(currentPart?.currency)
      setCheckedCars(currentPart.cars.map((car) => car.id))
      setIsDisabledButton(false)
    }
  }, [currentPart])

  const handleSavePart = async () => {
    console.log(`id = ${id}`)
    if (id !== -1) {
      await dispatch(
        editPart({
          id,
          name,
          number,
          amount,
          currency,
          checkedCars,
        })
      ).unwrap()
      dispatch(fetchParts())
    } else {
      await dispatch(
        addPart({
          id,
          name,
          number,
          amount,
          currency,
          checkedCars,
        })
      ).unwrap()
      dispatch(fetchParts())
    }
    
  }

  useEffect(() => {
    // const isNameChanged = name !== currentPart?.name;
    // const isNumberChanged = number !== currentPart?.number.toString();
    // const isAmmountChanged = amount !== currentPart?.amount;
    // const isCurrencyChanged = currency !== currentPart?.currency;
    const isAmountValid = amount !== null && amount !== undefined && amount > 0;
    const isFieldFilled = name.trim() !== '' && number.trim() !== '' && isAmountValid && currency.trim() !== '';
  
    setIsDisabledButton(!isFieldFilled);
  }, [name, number, amount, currency, currentPart]);
  
  

  const renderComponent = () => {
    const mainInfoProps = {
      name,
      setName,
      number,
      setNumber,
      amount,
      setAmmount,
      currency,
      setCurrency,
      errors,
      setErrors,
      checkedCars,
      setCheckedCars,
    }
    return <MainInfo {...mainInfoProps} />
  }

  // HANDLE ERROR
  const [errors, setErrors] = useState({
    name: false,
    number: false,
    ammount: false,
    currency: false,
  })

  return (
    <div style={{ display: 'block' }} className={styles.win} id="win_part_edit">
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalPart(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <div
              style={{ textAlign: 'center' }}
              className={classNames(styles.h3, 'mb-5')}
            >
              {currentPart ? 'Редактировать запчасть' : 'Добавить запчасть'}
            </div>
            {renderComponent()}

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={handleSavePart}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                    disabled={isDisabledButton}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    {currentPart ? 'Сохранить' : 'Добавить'}
                  </button>
                </div>

                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalPart(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartModal
