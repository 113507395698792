import React, { useEffect, useState } from 'react'

import styles from './ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchAddReports,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import classNames from 'classnames'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { getRole } from '../../../utils/getRole'

//@ts-ignore
import ModalImage from 'react-modal-image'
import { companySelector } from '../../../redux/selectors/company-selector'
import { toast } from 'react-toastify'
import { IMAGE_TYPE } from '../../../constants/file-types'
import { reportFilterSelector } from '../../../redux/selectors/report-filter-selector'
import { fetchUsers } from '../../../redux/reducers/users'
import {
  driversAndAssistantsSelector,
  usersSelector,
} from '../../../redux/selectors/users-selector'
type FileArray = File[]

const formattedDate = (dateStr: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(dateStr)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month} ${year}` // '12 Sep 2023'
}

function ReportModal() {
  const { isAdmin, isAssistant } = getRole()
  const dispatch = useDispatch<AppDispatch>()

  const { cars } = useSelector(carsSelector)
  const { currentReport } = useSelector(reportsSelector)
  const { currentCompany } = useSelector(companySelector)

  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [truck, setTruck] = useState<string | undefined>(undefined)
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [expenses, setExpenses] = useState('')
  const [route, setRoute] = useState('')
  const [date, setDate] = useState('')

  const [photos, setPhotos] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])
  const [receiptRemove, setReceiptRemove] = useState<Array<number | string>>([])
  const [isTimeValid, setIsTimeValid] = useState(false)

  const [isVisibleExpenses, setIsVisibleExpenses] = useState(false)

console.log('currentReport')
console.log(currentReport)


  // ДАННЫЕ
  const [fuelBefore, setFuelBefore] = useState('');
  const [fuelAfter, setFuelAfter] = useState('');

  // Установка данных
  useEffect(()=>{

  },[])

  useEffect(() => {
    if (startTime && endTime) {
      if (startTime >= endTime) {
        setIsTimeValid(true)
      } else {
        setIsTimeValid(false)
      }
    }
  }, [endTime, startTime, currentReport])

  const handleStartTimeChange = (e: any) => {
    const newStartTime = e.target.value
    setStartTime(newStartTime)
  }

  const handleEndTimeChange = (e: any) => {
    const newEndTime = e.target.value
    setEndTime(newEndTime)
  }

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // Проверка на null перед использованием Array.from
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  // useEffect(() => {
  //   if (currentReport) {
  //     const [newDateStart, timeWithSeconds] =
  //       currentReport?.date_from.split(' ')
  //     const [hours, minutes] = timeWithSeconds.split(':')

  //     const [_, timeWithSecondsEnd] = currentReport?.date_to.split(' ')
  //     const [hoursEnd, minutesEnd] = timeWithSecondsEnd.split(':')

  //     const timeStart = hours + ':' + minutes
  //     const timeTo = hoursEnd + ':' + minutesEnd

  //     setStartTime(timeStart)
  //     setEndTime(timeTo)
  //     setDate(newDateStart)
  //     setExpenses(currentReport?.cost)
  //     setRoute(currentReport?.distance)
  //     setTruck(currentReport?.car?.id)
  //     setPhotos(currentReport?.receipts)
  //   }
  // }, [])

  const handleChangeDate = (event: any) => {
    setDate(event.target.value)
  }

  const handleSendReport = () => {
    if (
      !isAssistant &&
      truck &&
      startTime &&
      endTime &&
      expenses &&
      route &&
      date
    ) {
      if (isAdmin) {
        dispatch(
          fetchAddReports({
            truck,
            startTime,
            endTime,
            expenses,
            route,
            date,
            photos: selectedPhotoForSend,
            id: currentReport?.id,
            receiptRemove,
          })
        )
      } else {
        dispatch(
          fetchAddReports({
            truck,
            startTime,
            endTime,
            expenses,
            route,
            date,
            photos: selectedPhotoForSend,
            id: currentReport?.id,
            receiptRemove,
          })
        )
      }
    }

    if (isAssistant && truck && startTime && endTime && date) {
      dispatch(
        fetchAddReports({
          truck,
          startTime,
          endTime,
          date,
          id: currentReport?.id,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const usersForFilter = useSelector(driversAndAssistantsSelector)

  console.log('usersForFilter')
  console.log(usersForFilter)

  //@ts-ignore
  useSelector(() => {}, [selectedPhoto])

  const handleDeletePhoto = (index: number) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((_, i) => i !== index)
    )
  }

  const handleDeleteSaved = (idImage: number | string) => {
    setReceiptRemove((prev) => [...prev, idImage])
    //@ts-ignore
    setPhotos(photos?.filter((item) => item?.id !== idImage))
  }

  useEffect(() => {
    if (isAssistant || currentReport?.user?.role === 'assistant') {
      if (truck && startTime && endTime && date && !isTimeValid) {
        setIsDisabledButton(false)
      } else {
        setIsDisabledButton(true)
      }
    } else {
      if (
        truck &&
        startTime &&
        endTime &&
        expenses &&
        route &&
        date &&
        !isTimeValid
      ) {
        setIsDisabledButton(false)
      } else {
        setIsDisabledButton(true)
      }
    }
  }, [date, endTime, expenses, isTimeValid, route, startTime, truck])

  const getExpenses = () => {
    return (
      <div
        className={classNames(
          styles.bxShadowSmall,
          styles.bgGray,
          styles.border10,
          styles.small2,
          styles.docItem,
          'pl-2 pr-2 mb-3'
        )}
      >
        <div className="row">
          <div
            className={classNames(
              'col-5 col-sm-3 pr-2 pt-1 pb-1',
              styles.docDate,
              styles.small3
            )}
          >
            20.07.2024&nbsp; 02:30
          </div>
          <div className="col-4 col-sm-2 pr-2 pt-1 pb-1">
            <b>Топливо</b>
          </div>
          <div
            className={classNames(
              styles.nowrap,
              styles.small3,
              'col-3 col-sm-1 pr-2 pt-1 pb-1'
            )}
            style={{ textAlign: 'right' }}
          >
            20 л
          </div>
          <div className="col col-sm-2 pt-1 pb-1">
            <b className={classNames(styles.nowrap, styles.colorRed)}>
              510.98 €
            </b>
            &nbsp;&nbsp;{' '}
            <span className={classNames(styles.small3, styles.colorLightBlue)}>
              Нал
            </span>
          </div>
          <div
            className={classNames(styles.nowrap, 'col pt-1 pb-1')}
            style={{ textAlign: 'right' }}
          >
            <a
              data-lightbox="note_money_1"
              className={classNames(
                styles.btn,
                styles.btnsmall2,
                styles.btnTrans
              )}
            >
              <i
                className={classNames(
                  styles.icon,
                  styles.icon15,
                  styles.iconPicture
                )}
              ></i>
              3
            </a>
            <a
              href="#"
              className={classNames(
                styles.btn,
                styles.btnsmall2,
                styles.btnBlueOutline,
                styles.btnIcon
              )}
            >
              <i className={classNames(styles.icon, styles.iconEdit)}></i>
            </a>
            <div
              className={classNames(
                styles.btn,
                styles.btnsmall2,
                styles.btnOutline,
                styles.btnIcon
              )}
            >
              <i className={classNames(styles.icon, styles.iconDeleteRed)}></i>
            </div>
          </div>
        </div>
        {/* <div>
        <a
          href="img/temp/photo-02.jpg"
          data-lightbox="note_money_1"
        ></a>
        <a
          href="img/temp/photo-03.jpg"
          data-lightbox="note_money_1"
        ></a>
      </div> */}
      </div>
    )
  }

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalReport(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`${isAdmin ? '' : 'mb-5'} ${styles.h3}}`)}
            >
              {currentReport ? 'Редактирование отчёта' : 'Новый отчёт'}
            </h3>

            {/* {!isAdmin ? (
              <div style={{ textAlign: 'center' }} className="mb-3">
                <div className="report-date" id="report_date_input_wrapper">
                  <input
                    min={currentCompany?.reportMinDate || undefined}
                    className={styles.date}
                    type="date"
                    value={date}
                    onChange={handleChangeDate}
                    pattern="\d{4}-\d{2}-\d{2}"
                  />
                </div>
              </div>
            ) : (
              <div className={styles.title} style={{ textAlign: 'center' }}>
                <b>{formattedDate(date)}</b>/{' '}
                <span> {currentReport?.user?.name}</span>
              </div>
            )} */}

            <form
              method="post"
              id="report_form_new"
              name="report_form_new"
              action="#"
            >
              {/* <!-- ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}
              <div
                className={classNames(
                  styles.bxShadowSmall,
                  styles.bgGray,
                  styles.border10,
                  styles.small2,
                  styles.docItem,
                  'pt-2 pb-2 pl-3 pr-3 mb-3 '
                )}
              >
                <div
                  className={classNames(styles.row, 'row', 'align-items-start')}
                >
                  <div className="col-12 col-sm-6 mt-1 mb-1">
                    <small
                      className={classNames(styles.small2, 'd-block mb-1')}
                    >
                      Водитель
                    </small>

                    {/* <select
                    className={errors.clientId ? styles.isError : ''}
                    value={clientId}
                    onChange={handleSelectChangeClient}
                  >
                    <option value="">Выберите клиента</option>
                    {clients.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.entity ? item.entity_name : item.name}
                      </option>
                    ))}
                  </select> */}

                    <select required>
                      {usersForFilter.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6 col-sm-3 mt-1 mb-1">
                    <small
                      className={classNames(styles.small2, 'd-block mb-1')}
                    >
                      Ср. расход, л/100
                    </small>
                    <input type="text" />
                  </div>
                  <div className="col-6 col-sm-3 mt-1 mb-1">
                    <small
                      className={classNames(styles.small2, 'd-block mb-1')}
                    >
                      Статус
                    </small>
                    <select>
                      <option selected>Черновик</option>
                      <option>Отправлен</option>
                      <option>Закрыт</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <!-- / ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}

              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Дата и время выезда*
                  </small>
                  <div
                    className={styles.reportDate}
                    id="report_date_input_wrapper1"
                  >
                    <input
                      className={classNames(
                        styles.calendarInput,
                        styles.dateInput,
                        styles.dateInputTime
                      )}
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div className="row">
                    <div className="col-6 mb-3 pr-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Пробег До*, км
                      </small>
                      <input
                        value="1345"
                        required
                        id="new_route_before1"
                        type="text"
                      />
                    </div>
                    <div className="col-6 mb-3 pl-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Пробег После, км
                      </small>
                      <input value="" type="text" />
                    </div>
                    <div className="col-6 mb-3 pr-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Топливо До*, л
                      </small>
                      <input value={fuelBefore} onChange={(e)=> setFuelBefore(e.target.value)} required type="text" />
                    </div>
                    <div className="col-6 mb-3 pl-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Топливо После, л
                      </small>
                      <input value={fuelAfter} onChange={(e)=> setFuelAfter(e.target.value)} required type="text" />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Грузовик*
                  </small>
                  <select required>
                    <option value="">Выберите грузовик</option>
                    <option selected>DAF XF 105</option>
                    <option>Foton Auman</option>
                    <option>Iveco 35-18</option>
                    <option>Ford Transit L4 H3</option>
                    <option>Mercedes-Benz Sprinter</option>
                    <option>Foton Aumark S120</option>
                    <option>Shacman X6000</option>
                    <option>MAN TGA 460</option>
                  </select>
                </div>
                <div className="col-6 col-sm-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Прицеп
                  </small>
                  <select>
                    <option value="">Выберите прицеп</option>
                    <option selected>DAF XF 105</option>
                    <option>Foton Auman</option>
                    <option>Iveco 35-18</option>
                    <option>Ford Transit L4 H3</option>
                    <option>Mercedes-Benz Sprinter</option>
                    <option>Foton Aumark S120</option>
                    <option>Shacman X6000</option>
                    <option>MAN TGA 460</option>
                  </select>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Пересечение границы туда
                  </small>
                  <div
                    className={styles.reportDate}
                    id="report_date_input_tuda"
                  >
                    <input
                      className={classNames(
                        styles.calendarInput,
                        styles.dateInput,
                        styles.dateInputTime
                      )}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Пересечение границы обратно
                  </small>
                  <div
                    className={styles.reportDate}
                    id="report_date_input_obratno"
                  >
                    <input
                      className={classNames(
                        styles.calendarInput,
                        styles.dateInput,
                        styles.dateInputTime
                      )}
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-12 mt-3 mb-2">
                  <div className="">
                    <b>Деньги</b>
                  </div>
                  <div
                    className={classNames(
                      styles.small2,
                      'row align-items-start'
                    )}
                  >
                    <div
                      className={classNames(
                        styles.small2,
                        'col-12 col-sm-8 mt-1 mb-1'
                      )}
                    >
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Выдано в рейс
                      </small>
                      <div className="row">
                        {/* <!-- BYN --> */}
                        <div
                          className={classNames(styles.small2, 'col-4 pr-1')}
                        >
                          <div
                            className={classNames(
                              styles.inputCurrency,
                              styles.inputByn,
                              'mb-1'
                            )}
                          >
                            <input
                              type="text"
                              value="4000"
                              placeholder="Сумма"
                            />
                          </div>
                          <div className={styles.small3}>
                            Ост. <b className={styles.colorRed}>1,540</b>{' '}
                            <span className={styles.colorRed}>BYN</span>
                          </div>
                        </div>

                        {/* <!-- RUB --> */}
                        <div
                          className={classNames(
                            styles.small2,
                            'col-4 pl-1 pr-1'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.inputCurrency,
                              styles.inputRub,
                              'mb-1'
                            )}
                          >
                            <input type="text" placeholder="Сумма" />
                          </div>
                        </div>

                        {/* <!-- EUR --> */}
                        <div
                          className={classNames(styles.small2, 'col-4 pl-1')}
                        >
                          <div
                            className={classNames(
                              styles.inputCurrency,
                              styles.inputEur,
                              'mb-1'
                            )}
                          >
                            <input
                              type="text"
                              value="2000"
                              placeholder="Сумма"
                            />
                          </div>
                          <div className={styles.small3}>
                            Ост. <b className={styles.colorRed}>150</b>{' '}
                            <span className={styles.colorRed}> EUR</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.small2,
                        'col-12 col-sm-4  mt-1 mb-1'
                      )}
                    >
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Возмещ. расходов
                      </small>
                      <div
                        className="report-date"
                        id="money_date_input_wrapper"
                      >
                        <input
                          className={classNames(
                            styles.calendarInput,
                            styles.dateInput
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- РАСХОДЫ --> */}
                <div className="col-12 col-sm-12 mt-3 mb-2">
                  <b>Расходы</b>{' '}
                  <a
                    onClick={() => setIsVisibleExpenses(!isVisibleExpenses)}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,
                      styles.btnBlueOutline,
                      'ml-2'
                    )}
                  >
                    <b>+ Добавить</b>
                  </a>
                </div>

                <div className="col-12">
                  {/* <!-- NEW NOTE --> */}
                  {isVisibleExpenses && (
                    <div
                      className={classNames(
                        styles.bxShadowSmall,
                        styles.bgGray,
                        styles.small2,
                        styles.border10,
                        'pl-3 pr-3 mb-3'
                      )}
                      id="jsNewMoney"
                    >
                      <div className={classNames('row', styles.rowNote)}>
                        <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
                          <div
                            className={styles.reportDate}
                            id="report_date_input_wrapper2"
                          >
                            <input
                              className={classNames(
                                styles.calendarInput,
                                styles.dateInput,
                                styles.dateInputTime
                              )}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <select>
                            <option value="1">Топливо</option>
                            <option value="2">Иное</option>
                          </select>
                        </div>

                        <div className="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1 pr-1 mb-2">
                          <input value="" type="text" placeholder="Кол-во" />
                        </div>

                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <select>
                            <option value="cash">Нал</option>
                            <option value="cash_receipt">Нал (чек)</option>
                            <option value="card">Карта</option>
                            <option value="card_receipt">Карта (чек)</option>
                            <option value="fuel-card">Топл. карта</option>
                          </select>
                        </div>

                        <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-2">
                          <input type="text" placeholder="Сумма" />
                          <div
                            className={classNames(
                              styles.small3,
                              styles.radioTabs,
                              'd-flex flex-wrap justify-content-start'
                            )}
                            style={{ textAlign: 'left' }}
                          >
                            <div className="mt-1">
                              <input
                                name="currency_radio1"
                                id="currency_radio1"
                                type="radio"
                                value="1"
                              />
                              <label
                                className="ml-2 mr-0"
                                htmlFor="currency_radio1"
                              >
                                EUR
                              </label>
                            </div>
                            <div className="mt-1">
                              <input
                                name="currency_radio1"
                                id="currency_radio2"
                                type="radio"
                                value="2"
                              />
                              <label
                                className="ml-2 mr-0"
                                htmlFor="currency_radio2"
                              >
                                RUB
                              </label>
                            </div>
                            <div className="mt-1">
                              <input
                                name="currency_radio1"
                                id="currency_radio3"
                                type="radio"
                                value="3"
                              />
                              <label
                                className="ml-2 mr-0"
                                htmlFor="currency_radio3"
                              >
                                BYN
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <div
                            className={classNames(
                              styles.btn,
                              styles.btnBlueOutline,
                              styles.btnSmall2,
                              styles.btnWide,
                              styles.loadGallery
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.icon15,
                                styles.iconPicture
                              )}
                            ></i>
                            + Фото
                            <input type="file" id="report_gallery_1" />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 mt-2 pl-1 pr-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Пояснение
                          </small>
                          <div className={styles.noteText}></div>
                        </div>

                        <div className="col-12 pl-1 pr-1">
                          <div
                            className={classNames(
                              styles.loadGalleryImages,
                              'mt-3'
                            )}
                          ></div>
                        </div>

                        <div className="col-8 pl-1 pr-1 mt-1">
                          <div
                            className={classNames(
                              styles.btn,
                              styles.btnsmall2,
                              styles.btnBlue,
                              'ml-2'
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconCheckWhite
                              )}
                            ></i>{' '}
                            Сохранить
                          </div>
                        </div>

                        <div
                          className="col-4 pl-1 pr-1 mt-1"
                          style={{ textAlign: 'right' }}
                        >
                          <div
                            className={classNames(
                              styles.btn,
                              styles.btnsmall2,
                              styles.btnTrans,
                              'ml-2'
                            )}
                          >
                            Отмена
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <!-- NOTES LIST --> */}
                  {getExpenses()}
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-12 col-sm-3 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                ></div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'center' }}
                >
                  <button className="btn d-block d-sm-inline-block">
                    <i className="icon icon-check-white"></i> Сохранить
                  </button>
                </div>
                <div
                  className="col-12 col-sm-3 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div className="btn btn-small btn-blue-outline  d-block d-sm-inline-block">
                    Отмена
                  </div>
                </div>
              </div>
            </form>

            {/* <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-sm-6 mb-3">
                  <small className="d-block mb-1">Truck</small>
                  <select
                    value={truck}
                    onChange={(e) => setTruck(e.target.value)}
                    required
                  >
                    <option value="">Select truck</option>
                    {cars.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.name}${!item.active ? ' (disabled)' : ''}`}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-6 col-sm-3 mb-3">
                  <small className="d-block mb-1">Start time</small>
                  <input
                    value={startTime}
                    onChange={handleStartTimeChange}
                    required
                    id="time_start"
                    name="time_start"
                    type="time"
                    step="60"
                  />
                  {isTimeValid && (
                    <small
                      className={classNames(styles.timeZone, styles.errorText)}
                    >
                      <b>Start time</b> must be less than <b>End time</b>
                    </small>
                  )}
                </div>

                <div className="col-6 col-sm-3 mb-3">
                  <small className="d-block mb-1">End time</small>
                  <input
                    value={endTime}
                    onChange={handleEndTimeChange}
                    required
                    id="time_end"
                    name="time_end"
                    type="time"
                    step="60"
                  />
                </div>

                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-6 col-sm-3 mb-3">
                      <small className="d-block mb-1">Expenses, EUR</small>
                      <input
                        value={expenses}
                        onChange={(e) => {
                          setExpenses(e.target.value)
                        }}
                        required
                        id="new_expenses"
                        name="expenses"
                        type="text"
                      />
                    </div>
                  )}

                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-6 col-sm-3 mb-3">
                      <small className="d-block mb-1">Route, km</small>
                      <input
                        value={route}
                        onChange={(e) => {
                          setRoute(e.target.value)
                        }}
                        required
                        id="new_route"
                        name="route"
                        type="text"
                      />
                    </div>
                  )}
                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-12 col-sm-6">
                      <small className="d-block mb-1">Expenses photos</small>
                      <div
                        id="load_gallery"
                        className={classNames(
                          styles.btn,
                          styles.btnBlueOutline,
                          styles.btnSmall,
                          styles.btnWide,
                          styles.loadGallery
                        )}
                      >
                        + Photos
                        <input
                          multiple={true}
                          onChange={handlePhotoChange}
                          type="file"
                          accept={IMAGE_TYPE}
                          name="report_gallery"
                          id="report_gallery"
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div
                id="load_gallery_images"
                className={classNames(`mt-3 ${styles.loadGalleryImages}}`)}
                style={{ textAlign: 'center' }}
              >
                <div className={styles.loadGalleryPhoto}>
                  {photos?.map(
                    (photo: {
                      id: string
                      receipt: { sm: string; md: string }
                    }) => {
                      return (
                        <div className={styles.reportsImgContainer}>
                          <ModalImage
                            key={photo.id}
                            className={`${styles.reportsImg} mb-1`}
                            small={
                              photo.receipt.sm || 'img/cars/no-car-photo.svg'
                            }
                            medium={
                              photo.receipt.md || 'img/cars/no-car-photo.svg'
                            }
                            alt="User Photo"
                          />

                          <span
                            onClick={() => handleDeleteSaved(photo.id)}
                            className={styles.del}
                          >
                            ×
                          </span>
                        </div>
                      )
                    }
                  )}
                  {selectedPhoto?.map((item, index) => {
                    return (
                      <div className={styles.reportsImgContainer}>
                        <ModalImage
                          key={index}
                          className={`${styles.reportsImg} mb-1`}
                          small={item || 'img/cars/no-car-photo.svg'}
                          medium={item || 'img/cars/no-car-photo.svg'}
                          alt="User Photo"
                        />
                        <span
                          onClick={() => handleDeletePhoto(index)}
                          className={styles.del}
                        >
                          ×
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                ></div>
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    onClick={handleSendReport}
                    className={classNames(
                      `${styles.btn} d-block d-sm-inline-block}`
                    )}
                    disabled={isDisabledButton}
                  >
                    <i
                      className={classNames(styles.icon, styles.iconCheckWhite)}
                    />
                    {currentReport ? 'Save' : 'Send'}
                  </button>
                </div>
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalReport(false))}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline,
                      'd-block',
                      'd-sm-inline-block'
                    )}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportModal
