import classNames from 'classnames'
import styles from './AddUserModal.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  // fetchAddUserForAdmin,
  fetchEditUser,
  setIsVisibleModalUser,
} from '../../../redux/reducers/users'
import { usersSelector } from '../../../redux/selectors/users-selector'
import { companySelector } from '../../../redux/selectors/company-selector'
import { getRole } from '../../../utils/getRole'
//@ts-ignore
import ModalImage from 'react-modal-image'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { IMAGE_TYPE } from '../../../constants/file-types'
import LoadingPage from '../../loading/LoadingPage'

const { isAdmin, isOwner } = getRole()

const roles = [
  { role: 'Водитель', value: 'driver' },
  { role: 'Помощник', value: 'assistant' },
  { role: 'Админ', value: 'admin' },
  { role: 'Владелец', value: 'owner' },
]

const MainInfo = ({
  photo,
  setPhoto,
  selectedImage,
  setSelectedImage,
  login,
  setLogin,
  isActive,
  setIsActive,
  name,
  setName,
  role,
  setRole,
  email,
  setEmail,
  phone2,
  setPhone2,
  phone,
  setPhone,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  errors,
  setErrors,
  id,
}: any) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setSelectedImage(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setPhoto(imageUrl)
    }
  }

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value)
      //@ts-ignore
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: !!e.target.value ? false : true,
      }))
    }

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <div className={styles.loadPhoto}>
            <div
              className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
            >
              <div className={`${styles.profileAvatar} mb-1`}>
                <ModalImage
                  className={`${styles.profileAvatar} mb-1`}
                  small={photo || 'img/users/no-avatar.png'}
                  alt="User Photo"
                />
              </div>

              <span className={styles.small}>Загрузить фото</span>

              <input
                type="file"
                accept={IMAGE_TYPE}
                name="photo"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Логин
          </small>

          <input
            value={login}
            onChange={handleInputChange(setLogin, 'login')}
            required
            type="text"
            placeholder="Логин"
            className={errors.login ? styles.isError : ''}
          />

          <div className={classNames('mt-3', styles.small)}>
            <input
              checked={isActive}
              type="checkbox"
              onChange={(e) => {
                setIsActive(e.target.checked)
              }}
              id="user_access"
              name="user_access"
              className={styles.input}
            />

            <label htmlFor="user_access">Доступ активен</label>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Имя
          </small>

          <input
            onChange={handleInputChange(setName, 'name')}
            value={name}
            required
            type="text"
            name="name"
            placeholder="Имя"
            className={errors.name ? styles.isError : ''}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Статус
          </small>
          <select
            value={role}
            onChange={(e) => {
              setRole(e.target.value)
              //@ts-ignore
              setErrors((prevErrors) => ({ ...prevErrors, role: false }))
            }}
            required
            className={classNames('mb-2', errors.role ? styles.isError : '')}
          >
            <option value={undefined}>Выберите роль</option>
            {roles.map((item) => {
              const { role, value } = item
              return <option value={value}>{role}</option>
            })}
          </select>
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            E-mail
          </small>
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            type="email"
            name="name"
            placeholder="E-mail"
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Телефоны (с кодом страны)
          </small>

          <input
            onChange={(e) => {
              setPhone(e.target.value)
            }}
            className="mb-1"
            value={phone || ''}
            type="text"
            name="name"
            placeholder="Телефон RUS"
          />

          <input
            onChange={(e) => {
              setPhone2(e.target.value)
            }}
            className="mb-1"
            value={phone2 || ''}
            type="text"
            name="name"
            placeholder="Телефон BY"
          />
        </div>
        <div className="col-12 mb-3">
          <b>Сменить пароль</b>
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Новый пароль
          </small>
          <input
            onChange={handleInputChange(setPassword, 'password')}
            value={password}
            required={!id}
            type="password"
            name="password"
            placeholder="Новый пароль"
            className={errors.password ? styles.isError : ''}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Повторите пароль
          </small>
          <input
            onChange={handleInputChange(setConfirmPassword, 'confirmPassword')}
            value={confirmPassword}
            required={!id}
            type="password"
            name="confirmPassword"
            placeholder="Повторите пароль"
            className={errors.confirmPassword ? styles.isError : ''}
          />
        </div>
      </div>
    </>
  )
}

const License = ({
  driversCardNumber,
  setDriversCardNumber,
  driversCardIssueDate,
  setDriversCardIssueDate,
  driversCardIssueAuthority,
  setDriversCardIssueAuthority,
  driversLicensedNumber,
  setDriversLicensedNumber,
  driversLicensedIssueDate,
  setDriversLicensedIssueDate,
  driversLicensedIssueAuthority,
  setDriversLicensedIssueAuthority,
}: any) => {
  return (
    <div className="tab-content" data-content="tab_profile_drive">
      <div className="mb-2">
        <b>Водительское удостоверение</b>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Номер
          </small>
          <input
            onChange={(e) => {
              setDriversLicensedNumber(e.target.value)
            }}
            value={driversLicensedNumber}
            type="text"
            name="name"
            placeholder="Номер"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Дата выдачи
          </small>
          <div className={styles.calendar}>
            <input
              className={classNames(styles.calendarInput, styles.date)}
              value={driversLicensedIssueDate}
              type="date"
              id="pass_date"
              // placeholder="Дата выдачи"
              onChange={(e) => setDriversLicensedIssueDate(e.target.value)}
              pattern="\d{4}-\d{2}-\d{2}"
            />
          </div>
        </div>
        <div className="col-12 col-sm-5 col-md-5 col-lg-5 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Кем выдано
          </small>

          <input
            onChange={(e) => {
              setDriversLicensedIssueAuthority(e.target.value)
            }}
            value={driversLicensedIssueAuthority}
            type="text"
            name="name"
            placeholder="Кем выдано"
          />
        </div>
      </div>

      <div className="mb-2">
        <b>Карта водителя</b>
      </div>
      <div className="row">
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Номер
          </small>

          <input
            onChange={(e) => {
              setDriversCardNumber(e.target.value)
            }}
            value={driversCardNumber}
            type="text"
            name="name"
            placeholder="Номер"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Дата выдачи
          </small>
          <div className={styles.calendar}>
            <input
              className={classNames(styles.calendarInput, styles.date)}
              value={driversCardIssueDate}
              type="date"
              id="pass_date"
              // placeholder="Дата выдачи"
              onChange={(e) => setDriversCardIssueDate(e.target.value)}
              pattern="\d{4}-\d{2}-\d{2}"
            />
          </div>
        </div>
        <div className="col-12 col-sm-5 col-md-5 col-lg-5 mb-3">
          <small className={classNames('mb-1', 'd-block', styles.small2)}>
            Кем выдано
          </small>
          <input
            onChange={(e) => {
              setDriversCardIssueAuthority(e.target.value)
            }}
            value={driversCardIssueAuthority}
            type="text"
            name="name"
            placeholder="Кем выдано"
          />
        </div>
      </div>
    </div>
  )
}

const Passport = ({
  passportSerial,
  setPassportSerial,
  passportNumber,
  setPassportNumber,
  passportDate,
  setPassportDate,
  passportIssue,
  setPassportIssue,
  passportRegistration,
  setPassportRegistration,
}: any) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
        <small className={classNames('mb-1', 'd-block', styles.small2)}>
          Серия и номер
        </small>
        <input
          value={passportSerial}
          onChange={(e) => setPassportSerial(e.target.value)}
          type="text"
          placeholder="Серия и номер паспорта"
        />
      </div>
      <div className="col-12 col-sm-8 col-md-8 col-lg-8 mb-3">
        <small className={classNames('mb-1', 'd-block', styles.small2)}>
          Идентиф. номер
        </small>
        <input
          value={passportNumber}
          onChange={(e) => setPassportNumber(e.target.value)}
          type="text"
          placeholder="Идентификационный номер"
        />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
        <small className={classNames('mb-1', 'd-block', styles.small2)}>
          Дата выдачи
        </small>
        <div className={styles.calendar}>
          <input
            className={classNames(styles.calendarInput, styles.date)}
            value={passportDate}
            type="date"
            id="pass_date"
            // placeholder="Дата выдачи"
            onChange={(e) => setPassportDate(e.target.value)}
            pattern="\d{4}-\d{2}-\d{2}"
          />
        </div>
      </div>
      <div className="col-12 col-sm-8 col-md-8 col-lg-8 mb-3">
        <small className={classNames('mb-1', 'd-block', styles.small2)}>
          Кем выдан
        </small>
        <input
          value={passportIssue}
          onChange={(e) => setPassportIssue(e.target.value)}
          type="text"
          placeholder="Кем выдан"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
        <small className={classNames('mb-1', 'd-block', styles.small2)}>
          Прописка
        </small>
        <input
          value={passportRegistration}
          onChange={(e) => setPassportRegistration(e.target.value)}
          type="text"
          placeholder="Прописка"
        />
      </div>
    </div>
  )
}

function AddUserModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentUserByRequest } = useSelector(usersSelector)
  useClearBodyNoScrollClass()

  // MAIN INFO
  const [photo, setPhoto] = useState<null | string>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [login, setLogin] = useState('')
  const [isActive, setIsActive] = useState(
    currentUserByRequest?.active || false
  )
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [phone2, setPhone2] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // License

  const [driversCardNumber, setDriversCardNumber] = useState('')
  const [driversCardIssueDate, setDriversCardIssueDate] = useState('')
  const [driversCardIssueAuthority, setDriversCardIssueAuthority] = useState('')

  const [driversLicensedNumber, setDriversLicensedNumber] = useState('')
  const [driversLicensedIssueDate, setDriversLicensedIssueDate] = useState('')
  const [driversLicensedIssueAuthority, setDriversLicensedIssueAuthority] =
    useState('')

  // Passport
  const [passportSerial, setPassportSerial] = useState('')
  const [passportNumber, setPassportNumber] = useState('')
  const [passportDate, setPassportDate] = useState('')
  const [passportIssue, setPassportIssue] = useState('')
  const [passportRegistration, setPassportRegistration] = useState('')

  const [errors, setErrors] = useState({
    login: false,
    name: false,
    role: false,
    password: false,
    confirmPassword: false,
  })

  const checkValidation = () => {
    const newErrors = {
      login: !login,
      name: !name,
      role: !role,
      password: !currentUserByRequest?.id && !password,
      confirmPassword: !currentUserByRequest?.id && !confirmPassword,
    }

    setErrors(newErrors)
  }

  useEffect(() => {
    if (currentUserByRequest) {
      const {} = currentUserByRequest

      // setIsActive(active);
      setPhoto(currentUserByRequest?.avatar?.md)
    }
  }, [])

  const [currentTab, setCurrentTab] = useState(1)

  const [isDisabledButton, setIsDisabledButton] = useState(true)

  useEffect(() => {
    if (currentUserByRequest) {
      const {
        name,
        active,
        login = '',
        phone,
        phone2,
        registration,
        email,
        role,
        avatar,
        passport,
        drivers_license,
        drivers_card,
      } = currentUserByRequest
      setName(name)
      setIsActive(active)
      setLogin(login)
      setPhone(phone)
      setPhone2(phone2)
      setPassportRegistration(registration)
      setEmail(email)
      setRole(role)
      setPhoto(avatar.sm)

      //passport

      setPassportSerial(passport?.identification_number)
      setPassportNumber(passport?.number)
      setPassportDate(passport?.issue_date)
      setPassportIssue(passport?.issue_authority)

      setDriversCardNumber(drivers_card?.number)
      setDriversCardIssueDate(drivers_card?.issue_date)
      setDriversCardIssueAuthority(drivers_card?.issue_authority)

      setDriversLicensedNumber(drivers_license?.number)
      setDriversLicensedIssueDate(drivers_license?.issue_date)
      setDriversLicensedIssueAuthority(drivers_license?.issue_authority)
    }
  }, [])

  const handleSaveUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const commonPayload = {
      photo,
      selectedImage,
      login,
      isActive,
      name,
      role,
      email,
      phone,
      phone2,
      password,
      confirmPassword,
      driversCardNumber,
      driversCardIssueDate,
      driversCardIssueAuthority,
      driversLicensedNumber,
      driversLicensedIssueDate,
      driversLicensedIssueAuthority,
      passportSerial,
      passportNumber,
      passportDate,
      passportIssue,
      passportRegistration,
    }

    const newErrors = {
      password: !currentUserByRequest?.id && !password,
      confirmPassword: !currentUserByRequest?.id && !confirmPassword,
      login: !login,
      name: !name,
      role: !role,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      dispatch(
        fetchEditUser({
          ...commonPayload,
          id: currentUserByRequest?.id,
        })
      )
    }
  }

  // const handleSaveCar = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  // }

  // useEffect(() => {
  //   const isNewUser = !currentUserByRequest?.id
  //   const isValidNewUser =
  //     login &&
  //     role &&
  //     role !== 'Select a role' &&
  //     name &&
  //     password &&
  //     confirmPassword === password
  //   const isValidPassword = confirmPassword === password && password !== ''

  //   const isActiveWasChanged = isActive === currentUserByRequest?.active

  //   const isUnchangedExistingUser =
  //     (!name || name === currentUserByRequest?.name) &&
  //     email === currentUserByRequest?.email &&
  //     (!login || login === currentUserByRequest?.login) &&
  //     role === currentUserByRequest?.role &&
  //     !isValidPassword &&
  //     isActiveWasChanged
  //   const areRequiredFieldsFilled =
  //     name !== '' && login !== '' && confirmPassword === password

  //   setIsDisabledButton(
  //     (isNewUser ? !isValidNewUser : isUnchangedExistingUser) ||
  //       !areRequiredFieldsFilled
  //   )
  // }, [
  //   confirmPassword,
  //   currentUserByRequest,
  //   email,
  //   name,
  //   password,
  //   role,
  //   login,
  //   isActive,
  // ])

  const renderComponent = () => {
    const mainInfoProps = {
      photo,
      setPhoto,
      selectedImage,
      setSelectedImage,
      login,
      setLogin,
      isActive,
      setIsActive,
      name,
      setName,
      role,
      setRole,
      email,
      setEmail,
      phone2,
      setPhone2,
      phone,
      setPhone,
      password,
      setPassword,
      confirmPassword,
      setConfirmPassword,
      errors,
      setErrors,
      id: currentUserByRequest?.id,
    }

    const licenseProps = {
      driversCardNumber,
      setDriversCardNumber,
      driversCardIssueDate,
      setDriversCardIssueDate,
      driversCardIssueAuthority,
      setDriversCardIssueAuthority,
      driversLicensedNumber,
      setDriversLicensedNumber,
      driversLicensedIssueDate,
      setDriversLicensedIssueDate,
      driversLicensedIssueAuthority,
      setDriversLicensedIssueAuthority,
    }

    const passportProps = {
      passportSerial,
      setPassportSerial,
      passportNumber,
      setPassportNumber,
      passportDate,
      setPassportDate,
      passportIssue,
      setPassportIssue,
      passportRegistration,
      setPassportRegistration,
    }
    switch (currentTab) {
      case 1:
        return <MainInfo {...mainInfoProps} />

      case 2:
        return <License {...licenseProps} />

      case 3:
        return <Passport {...passportProps} />

      default:
        return <MainInfo {...mainInfoProps} />
    }
  }

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalUser(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`mb-5 ${styles.h3}}`)}
            >
              {currentUserByRequest
                ? currentUserByRequest.name
                : 'Новый сотрудник'}
            </h3>
            <div
              className={classNames('row align-items-end', styles.radioTabs)}
            >
              <div
                className={classNames(
                  styles.menuButton,
                  currentTab === 1 ? styles.menuButtonActive : '',
                  'col-4 col-sm-4 col-md-4 mt-2'
                )}
                onClick={() => setCurrentTab(1)}
              >
                Основное
              </div>
              <div
                className={classNames(
                  styles.menuButton,
                  currentTab === 2 ? styles.menuButtonActive : '',
                  'col-4 col-sm-4 col-md-4 mt-2'
                )}
                onClick={() => setCurrentTab(2)}
              >
                Права и карта
              </div>
              <div
                className={classNames(
                  styles.menuButton,
                  currentTab === 3 ? styles.menuButtonActive : '',
                  'col-4 col-sm-4 col-md-4 mt-2'
                )}
                onClick={() => setCurrentTab(3)}
              >
                Паспорт
              </div>
            </div>
            <form onSubmit={handleSaveUser}>
              {renderComponent()}
              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={checkValidation}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                    // disabled={isDisabledButton}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    Сохранить
                  </button>
                </div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalUser(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUserModal
